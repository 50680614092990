/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Capabilities } from './Capabilities';
import {
    CapabilitiesFromJSON,
    CapabilitiesFromJSONTyped,
    CapabilitiesToJSON,
} from './Capabilities';

/**
 * 
 * @export
 * @interface GeneralInformation
 */
export interface GeneralInformation {
    /**
     * 
     * @type {string}
     * @memberof GeneralInformation
     */
    name?: string;
    /**
     * Timestamp in ISO 8601 format.
     * @type {Date}
     * @memberof GeneralInformation
     */
    launchDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GeneralInformation
     */
    targetMarket?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralInformation
     */
    description?: string;
    /**
     * 
     * @type {Capabilities}
     * @memberof GeneralInformation
     */
    capabilities?: Capabilities;
}

/**
 * Check if a given object implements the GeneralInformation interface.
 */
export function instanceOfGeneralInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GeneralInformationFromJSON(json: any): GeneralInformation {
    return GeneralInformationFromJSONTyped(json, false);
}

export function GeneralInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'launchDate': !exists(json, 'launchDate') ? undefined : (new Date(json['launchDate'])),
        'targetMarket': !exists(json, 'targetMarket') ? undefined : json['targetMarket'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'capabilities': !exists(json, 'capabilities') ? undefined : CapabilitiesFromJSON(json['capabilities']),
    };
}

export function GeneralInformationToJSON(value?: GeneralInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'launchDate': value.launchDate === undefined ? undefined : (value.launchDate.toISOString()),
        'targetMarket': value.targetMarket,
        'description': value.description,
        'capabilities': CapabilitiesToJSON(value.capabilities),
    };
}

