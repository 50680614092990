import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Message} from '@sabre/spark-react-core';
import {StyledH2} from './ContactDetails';
import styled from 'styled-components';
import {ButtonSize, MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {EventCategories} from './TermsAndConditions';
import {GaContext} from '../../../utils/gaContext';
import {onboardingDeveloperPartnerLatestStepName} from '../../JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";

const Pricing = ({
                   setCurrentStep,
                   currentStep,
                 }: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
}) => {
  const {formatMessage} = useIntl();
  const {logEvent} = useContext(GaContext);

  const formSubmitHandler = () => {
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
      logEvent({
        category: EventCategories.JoinUs,
        action: buildGaActionLabel('ga.action.onboarding.movedToReviewAndSubmit', 'ga.tag.partnerNetwork.developerPartner')
      });
      sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
    }
  };

  const previousStep = () => setCurrentStep(prevState => prevState - 1);

  return (
    <div>
      <div className="spark-panel">
        <div className="spark-panel__content">
          <StyledH2>
            <FormattedMessage id="joinUsPage.form.pricing.title"/>
          </StyledH2>
          <Message
            content={formatMessage({id: 'joinUsPage.form.newPricing.content'})}
            role={MessageRole.ALERT}
            status={MessageStatus.WARNING}
            title={formatMessage({id: "joinUsPage.form.newPricing.title"})}
            type={MessageType.PAGE}
          />
        </div>
      </div>
      <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
        <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
          <FormattedMessage id="joinUsPage.form.backButton"/>
        </Button>
        <Button type="button" size={ButtonSize.SMALL} onClick={formSubmitHandler}>
          <FormattedMessage id="joinUsPage.form.button"/>
        </Button>
      </nav>
    </div>
  );
};

export const FullHeightContainer = styled.div`
  min-height: 100vh;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Pricing;
