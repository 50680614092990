import {Quill} from 'quill';
import styled from "styled-components";

export const encodeHtmlEntities = (str: string) => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
};

export const countEncodedTags = (encodedHtmlText: string) => {
  const encodedTagPattern = /&[a-zA-Z]+;/g;
  const matches = encodedHtmlText.match(encodedTagPattern);
  return matches ? matches.reduce((acc, tag) => acc + tag.length, 0) : 0;
};

export const textUtils = (editor: Quill) => {
  const text = editor.getText();
  const encodedText = encodeHtmlEntities(text);
  return encodedText.length;
};

export const replaceQuillIndentWithNestedLists = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const listElements = doc.querySelectorAll('ul, ol');

  listElements.forEach(list => {
    const liElements = Array.from(list.children);
    let currentIndentLevel = 0;
    let currentList = list;

    liElements.forEach(li => {
      const indentMatch = li.className.match(/ql-indent-(\d+)/);
      const indentLevel = indentMatch ? parseInt(indentMatch[1], 10) : 0;

      if (indentLevel > currentIndentLevel) {
        const newList = document.createElement(currentList.tagName.toLowerCase());
        currentList.lastElementChild?.appendChild(newList);
        newList.appendChild(li);
        currentList = newList;
      } else if (indentLevel < currentIndentLevel) {
        let parentList = currentList;
        for (let i = 0; i < currentIndentLevel - indentLevel; i++) {
          parentList = parentList.parentElement?.closest('ul, ol') || list;
        }
        parentList.appendChild(li);
        currentList = parentList;
      } else {
        currentList.appendChild(li);
      }

      currentIndentLevel = indentLevel;
      li.classList.remove(`ql-indent-${indentLevel}`);
      if (li.className === '') {
        li.removeAttribute('class');
      }
    });
  });

  return doc.body.innerHTML;
};

const removesDuplicatedLists = (html: string) => html
  .replace(/<ol>(\s*<ol>)+/g, '<ol>')
  .replace(/<\/ol>(\s*<\/ol>)+/g, '</ol>')
  .replace(/<ul>(\s*<ul>)+/g, '<ul>')
  .replace(/<\/ul>(\s*<\/ul>)+/g, '</ul>')
  .replace(/\s+/g, ' ');

const convertNestedListsToQuillIndent = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const listElements = doc.querySelectorAll('ul, ol');

  listElements.forEach(list => {
    const processList = (list: HTMLUListElement | HTMLOListElement, indentLevel: number) => {
      const liElements = Array.from(list.children);
      liElements.forEach(li => {
        if (li.tagName === 'LI') {
          li.classList.add(`ql-indent-${indentLevel}`);
          const nestedList = li.querySelector('ul, ol') as HTMLUListElement | HTMLOListElement;
          if (nestedList) {
            processList(nestedList, indentLevel + 1);
            while (nestedList.firstChild) {
              if (li.parentElement && nestedList.parentElement) {
                li.appendChild(nestedList.firstChild);
              } else {
                break;
              }
            }
            if (nestedList.parentElement) {
              nestedList.remove();
            }
          }
        }
      });
    };
    processList(list as HTMLUListElement | HTMLOListElement, 0);
  });

  return doc.body.innerHTML;
}

const standardizeListsClasses = () => {
  const BASIC_SELECTOR = "ol > li ";
  const NUMBER_OF_SELECTORS = 20;
  let result = "";
  let listTypeIndex = 1;

  const generateListType = () => {
    const listType = listTypeIndex % 3 === 0 ? "list-style-type: lower-roman" : listTypeIndex % 2 === 0 ? "list-style-type: lower-alpha" : "list-style-type: decimal";
    listTypeIndex++;
    if (listTypeIndex === 3) {
      listTypeIndex = 0;
    }
    return listType;
  }

  for (let i = 1; i < NUMBER_OF_SELECTORS; i++) {
    result = `
    ${result}
    ${BASIC_SELECTOR.repeat(i)} {
      ${generateListType()}
    }
    `
  }
  return result;
}

export const IndentViewWrapper = styled.div`
  .ql-indent-1 {
    padding-left: 3em;
  }

  .ql-indent-2 {
    padding-left: 6em;
  }

  .ql-indent-3 {
    padding-left: 9em;
  }

  .ql-indent-4 {
    padding-left: 12em;
  }

  .ql-indent-5 {
    padding-left: 15em;
  }

  .ql-indent-6 {
    padding-left: 18em;
  }

  .ql-indent-7 {
    padding-left: 21em;
  }

  .ql-indent-8 {
    padding-left: 24em;
  }

  /* Define custom list styles */
  ${standardizeListsClasses()}
`;

export const standardizeListsMarkers = () => {
  const NUMBER_OF_SELECTORS = 20;
  let result = "";

  for (let i = 1; i < NUMBER_OF_SELECTORS; i++) {
    const firstItem = i;
    const secondItem = i + 1;

    result = `
    ${result}

    & li.ql-indent-${firstItem} + li.ql-indent-${secondItem} {
      counter-set: list-${secondItem} 1;
    }
    `;
  }

  return result;
}

export const addStylePaddingToIndentedParagraphs = (htmlString: string) => {
  const paddingValues: { [key: string]: string } = {
    'ql-indent-1': '3em',
    'ql-indent-2': '6em',
    'ql-indent-3': '9em',
    'ql-indent-4': '12em',
    'ql-indent-5': '15em',
    'ql-indent-6': '18em',
    'ql-indent-7': '21em',
    'ql-indent-8': '24em',
  };

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const paragraphs = doc.querySelectorAll('p[class*="ql-indent-"]');

  paragraphs.forEach(paragraph => {
    const classes = paragraph.className.split(' ');
    classes.forEach(cls => {
      if (paddingValues[cls]) {
        const existingStyle = paragraph.getAttribute('style') || '';
        const newStyle = `padding-left: ${paddingValues[cls]};`;
        paragraph.setAttribute('style', `${existingStyle} ${newStyle}`.trim());
      }
    });
  });

  return doc.body.innerHTML;
};

export const removeStylePaddingFromParagraphs = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const paragraphs = doc.querySelectorAll('p');

  paragraphs.forEach(paragraph => {
    const style = paragraph.getAttribute('style');
    if (style) {
      const newStyle = style.replace(/padding-left:\s*[^;]+;?/g, '').trim();
      if (newStyle) {
        paragraph.setAttribute('style', newStyle);
      } else {
        paragraph.removeAttribute('style');
      }
    }
  });

  return doc.body.innerHTML;
};

const addListStyleTypes = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const listElements = doc.querySelectorAll('ol:not(li ol)');

  const listTypes = ['decimal', 'lower-alpha', 'lower-roman'];

  const applyListStyle = (list: HTMLOListElement, level: number) => {
    const listItems = Array.from(list.children) as HTMLLIElement[];
    const nestedLists: HTMLOListElement[] = [];
    listItems.forEach(li => {
      li.style.listStyleType = listTypes[level];
      const nestedList = li.querySelector('ol');
      if (nestedList) {
        nestedLists.push(nestedList as HTMLOListElement);
      }
    })

    const newLevel = level + 1 === 3 ? 0 : level + 1;
    nestedLists.forEach((nestedList, index) => {
      applyListStyle(nestedList, newLevel);
    });
  };


  listElements.forEach(list => applyListStyle(list as HTMLOListElement, 0));

  return doc.body.innerHTML;
};

const removeStyleFromListItems = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const listItems = doc.querySelectorAll('li');

  listItems.forEach(listItem => {
    listItem.removeAttribute('style');
  });

  return doc.body.innerHTML;
};

const replaceMultipleBrParagraphs = (html: string): string => {
  return html.replace(/(<p><br><\/p>\s*)+/g, '<p><br></p>');
};

export const formatRichTextFromSf = (html: string) => removeStyleFromListItems(removeStylePaddingFromParagraphs(convertNestedListsToQuillIndent(removesDuplicatedLists(html))));

export const formatRichTextToSf = (html: string) => addListStyleTypes(addStylePaddingToIndentedParagraphs(replaceQuillIndentWithNestedLists(replaceMultipleBrParagraphs(html))));
