import {FormattedMessage, useIntl} from "react-intl";
import {StyledH2} from "./ContactDetails";
import {Text} from "@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage";
import React, {Dispatch, SetStateAction, useContext} from "react";
import {FormProvider, Resolver, SubmitHandler, useForm} from "react-hook-form";
import {Fields} from "../../components/EndMessage";
import {Button} from "@sabre/spark-react-core";
import {ButtonSize} from "@sabre/spark-react-core/types";
import {OnboardDeveloperPartnerExtendedRequest} from "../../../generated/onboardings";
import useYupValidationResolver, {getFieldMaxLength, isFieldRequired} from "../../components/useYupValidationResolver";
import schema from "../../../onboarding-schema/sabreInteractions.schema";
import {GaContext} from "../../../utils/gaContext";
import {onboardingDeveloperPartnerLatestStepName} from "../../JoinUsContentBox";
import {EventCategories} from "./TermsAndConditions";
import {buildGaActionLabel} from "@scm/components/ga/actions";
import SparkInput from "@scm/components/form/SparkInput";
import styled from "styled-components";

const SabreInteractions = ({saveHandler, onboardDeveloperPartnerRequest, currentStep, setCurrentStep}: {
  saveHandler: Dispatch<SetStateAction<OnboardDeveloperPartnerExtendedRequest>>;
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {formatMessage} = useIntl();

  const resolver = useYupValidationResolver(schema);

  const methods = useForm<OnboardDeveloperPartnerExtendedRequest>({
    defaultValues: onboardDeveloperPartnerRequest,
    mode: 'onChange',
    resolver: resolver as Resolver<OnboardDeveloperPartnerExtendedRequest>,
  });

  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardDeveloperPartnerExtendedRequest> = (data: OnboardDeveloperPartnerExtendedRequest) => {
    saveHandler(data);
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
      logEvent({
        category: EventCategories.JoinUs,
        // TODO: TO confirm action
        action: buildGaActionLabel('ga.action.onboarding.movedToProjectScopeSi', 'ga.tag.partnerNetwork.developerPartner')
      });
      sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
    }
  };

  const previousStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.sabreInteractions.title"/>
            </StyledH2>
            <Text>
              <FormattedMessage id="joinUsPage.form.sabreInteractions.description"/>
            </Text>
            <Fields>
              <SparkInput
                name="projectScope.sabreInteractions.sabreApi"
                value={onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.sabreApi ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.sabreApi.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.sabreApi.label'})}
                errorMessage={methods.formState.errors.projectScope?.sabreInteractions?.sabreApi?.message}
                isRequired={isFieldRequired('projectScope.sabreInteractions.sabreApi', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.sabreInteractions.sabreApi', schema)}
                isMultiline
              />
              <SparkInput
                name="projectScope.sabreInteractions.keySabreContent"
                value={onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.keySabreContent ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.keySabreContent.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.keySabreContent.label'})}
                errorMessage={methods.formState.errors.projectScope?.sabreInteractions?.keySabreContent?.message}
                isRequired={isFieldRequired('projectScope.sabreInteractions.keySabreContent', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.sabreInteractions.keySabreContent', schema)}
                isMultiline
              />
              <AdditionalText className="spark-small spark-mar-b-1">
                <FormattedMessage id="joinUsPage.form.projectScope.sabreInteractions.keySabreContent.additionalText"/>
              </AdditionalText>
              <SparkInput
                name="projectScope.sabreInteractions.keySabreFunctionality"
                value={onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.keySabreFunctionality ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.keySabreFunctionality.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.keySabreFunctionality.label'})}
                errorMessage={methods.formState.errors.projectScope?.sabreInteractions?.keySabreFunctionality?.message}
                isRequired={isFieldRequired('projectScope.sabreInteractions.keySabreFunctionality', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.sabreInteractions.keySabreFunctionality', schema)}
                isMultiline
              />
              <AdditionalText className="spark-small spark-mar-b-1">
                <FormattedMessage id="joinUsPage.form.projectScope.sabreInteractions.keySabreFunctionality.additionalText"/>
              </AdditionalText>
              <SparkInput
                name="projectScope.sabreInteractions.sabreScans"
                value={onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.sabreScans ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.sabreScans.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.sabreScans.label'})}
                errorMessage={methods.formState.errors.projectScope?.sabreInteractions?.sabreScans?.message}
                isRequired={isFieldRequired('projectScope.sabreInteractions.sabreScans', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.sabreInteractions.sabreScans', schema)}
                isMultiline
              />
              <SparkInput
                name="projectScope.sabreInteractions.creditCardStorage"
                value={onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.creditCardStorage ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.creditCardStorage.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.sabreInteractions.creditCardStorage.label'})}
                errorMessage={methods.formState.errors.projectScope?.sabreInteractions?.creditCardStorage?.message}
                isRequired={isFieldRequired('projectScope.sabreInteractions.creditCardStorage', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.sabreInteractions.creditCardStorage', schema)}
                isMultiline
              />
            </Fields>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton"/>
          </Button>
          <Button
            type="submit"
            disabled={!methods.formState.isValid}
            size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button"/>
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
}

const AdditionalText = styled.p`
    margin-top: -1rem;
`;

export default SabreInteractions;
