import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ButtonSize} from "@sabre/spark-react-core/types";
import {Button, Icon, Modal} from "@sabre/spark-react-core";
import styled from "styled-components";
import InfoColumn from "@scm/components/onboardingInfo/InfoColumn";
import {privateStandard} from "@scm/components/translations/infoTexts/privateStandard";
import {developerStandard} from "./developerStandard";

const DeveloperPartnerInfo = () => {
  const [open, setOpen] = useState(false);
  const handleClose = (): void => setOpen(false);
  const handleOpen = (): void => setOpen(true);
  const {formatMessage} = useIntl();

  return (
    <>
      <Container className="spark-mar-r-1">
        <Button secondary onClick={handleOpen} size={ButtonSize.SMALL}>
          <Icon iconStyle="line" name="document-checklist" size="sm" className="spark-mar-r-.5" />
          <FormattedMessage id="onboarding.info.button.label" />
        </Button>
      </Container>
      <StyledModal
        onClose={handleClose}
        open={open}
        modalHeader={
          <ModalHeader className="spark-mar-l-1 spark-mar-t-2">
            {formatMessage({id: 'onboarding.info.button.label'})}
          </ModalHeader>
        }
        ariaLabel={formatMessage({id: 'onboarding.info.button.label'})}
      >
        <div className="row mar-t-2">
          <InfoColumn
            textItems={{
              standardInfo: Object.values(developerStandard),
              noHeading: true,
            }}
          />
        </div>
        <div className="spark-btn-group">
          <Button
            className="spark-btn-group-secondary spark-mar-r-1 spark-mar-b-1"
            size={ButtonSize.SMALL}
            secondary
            onClick={handleClose}
          >
            <FormattedMessage id="onboarding.info.button.close" />
          </Button>
        </div>
      </StyledModal>
    </>
  );
}

const ModalHeader = styled.h3`
  font-weight: bold !important;
  font-size: 1.8rem;
`;

const Container = styled.div`
  display: inline-block;

  & .spark-icon {
    vertical-align: text-bottom !important;

    &::before {
      font-size: 1.55rem;
      line-height: 1.55rem;
      font-weight: bold;
    }
  }
`;

const StyledModal = styled(Modal)`
  & .col-xl-5 {
    max-width: 58.333333% !important;
    flex: 0 0 58.333333% !important;
  }

  & .col-lg-6 {
    max-width: 66.666666% !important;
    flex: 0 0 66.666666% !important;
  }
`;

export default DeveloperPartnerInfo;
