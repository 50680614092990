import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormProvider, useForm} from 'react-hook-form';
import ReviewHeader from '../../components/reviewComponents/ReviewHeader';
import {StyledH2} from './ContactDetails';
import {FullHeightContainer} from './Pricing';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {onboardingsApiBaseLink} from '../../../assets/apiBaseLink';
import {
  Configuration,
  OnboardDeveloperPartnerRequest,
  OnboardingsApi,
} from '../../../generated/onboardings';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import ReviewParagraphs from '@scm/components/form/review/ReviewParagraphs';
import {EventCategories} from './TermsAndConditions';
import {GaContext} from '../../../utils/gaContext';
import {onboardingDeveloperPartnerLatestStepName} from '../../JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";

export const postData = async (request: OnboardDeveloperPartnerRequest) =>
  await new OnboardingsApi(
    new Configuration({
      basePath: onboardingsApiBaseLink,
    })
  ).onboardDeveloperPartner({onboardDeveloperPartnerRequest: request});

async function handleData(
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerRequest,
  successHandler: () => void,
  errorHandler: () => void
) {
  try {
    const mapped = JSON.parse(JSON.stringify(onboardDeveloperPartnerRequest)) as OnboardDeveloperPartnerRequest;
    delete mapped['isImplementationFee' as keyof typeof mapped];

    await postData(mapped);

    successHandler();
  } catch (e) {
    errorHandler();
  }
}

const ReviewAndSubmit = ({
  onboardDeveloperPartnerRequest,
  setCurrentStep,
  currentStep,
}: {
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerRequest;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
}) => {
  const methods = useForm({mode: 'onChange'});
  const {logEvent} = useContext(GaContext);

  const formSubmitHandler = async function (success: () => void, failure: () => void) {
    const successHandler = () => {
      success();
      setCurrentStep(prevState => prevState + 1);
      if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
        logEvent({category: EventCategories.JoinUs, action: buildGaActionLabel('ga.action.onboarding.proposalSubmitted', 'ga.tag.partnerNetwork.developerPartner')});
        sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
      }
    };

    const errorHandler = () => {
      failure();
      setCurrentStep(-1);
    };

    await handleData(onboardDeveloperPartnerRequest, successHandler, errorHandler);
  };

  const previousStep = () => setCurrentStep(prevState => prevState - 1);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.reviewAndSubmit.title" />
            </StyledH2>
            <Text>
              <FormattedMessage id="joinUsPage.form.reviewAndSubmit.description" />
            </Text>
            <FullHeightContainer>
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.generalInformation" />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.title" type="h4" />
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.firstName.label',
                  'joinUsPage.form.contactDetails.lastName.label',
                  'joinUsPage.form.contactDetails.companyTitle.label',
                  'joinUsPage.form.contactDetails.email.label',
                  'joinUsPage.form.contactDetails.phone.label',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.contactDetails.firstName,
                  onboardDeveloperPartnerRequest.contactDetails.lastName,
                  onboardDeveloperPartnerRequest.contactDetails.titleInCompany,
                  onboardDeveloperPartnerRequest.contactDetails.email,
                  onboardDeveloperPartnerRequest.contactDetails.phone,
                ]}
              />
              <ReviewHeader content="joinUsPage.form.companyInformation.title" type="h4" />
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.fullCompanyName.label',
                  'joinUsPage.form.contactDetails.url.label',
                  'joinUsPage.form.contactDetails.staff.label',
                  'joinUsPage.form.contactDetails.companyYears.label',
                  'joinUsPage.form.contactDetails.solutionDescription.placeholder',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.companyInformation.name,
                  onboardDeveloperPartnerRequest.companyInformation.website || '',
                  onboardDeveloperPartnerRequest.companyInformation.numberOfEmployees || '',
                  onboardDeveloperPartnerRequest.companyInformation.yearsInBusiness || '',
                  onboardDeveloperPartnerRequest.companyInformation.businessProfile,
                ]}
              />
              <ReviewHeader content="joinUsPage.form.companyLocation.title" type="h4" />
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.countryCode.label',
                  'joinUsPage.form.contactDetails.provinceLocated.placeholder',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.companyInformation.locationCountry ?? '',
                  onboardDeveloperPartnerRequest.companyInformation.locationProvince ?? '',
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.companyAddress.title" type="h4" />
              <ReviewParagraphs
                label={Object.keys(onboardDeveloperPartnerRequest.companyInformation.address).map(
                  item => `joinUsPage.form.contactDetails.${item}.label`
                )}
                content={Object.values(onboardDeveloperPartnerRequest.companyInformation.address)}
              />
              <ReviewHeader content="joinUsPage.form.contactDetails.potentialSabreCustomer.title" type="h4" />
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.potentialSabreCustomer.name.label',
                  'joinUsPage.form.contactDetails.potentialSabreCustomer.pcc.label',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.sabreCustomerAccountInfo.name,
                  onboardDeveloperPartnerRequest.sabreCustomerAccountInfo.pcc,
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.termsAndCondition.title" />
              <ReviewParagraphs label={['joinUsPage.form.reviewAndSubmit.termsAndCondition.description']} />
            </FullHeightContainer>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.backButton" />
          </Button>
          <Button
            progress
            onClick={async (_, success, failure) => {
              if (success && failure) {
                await formSubmitHandler(success, failure);
              }
            }}
            size={ButtonSize.SMALL}
            data-testid="Submit"
          >
            <FormattedMessage id="joinUsPage.form.endButton" />
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

export default ReviewAndSubmit;
