import React, {useContext} from 'react';
import {ProposalContext} from '../../../ProposalForm';
import {
  connectToGdsDescriptionName, descriptionName,
  interactsWithEndTravelerDescriptionName,
  isPaidDescriptionName,
  ProposalValues,
  providePnrDocumentationDescriptionName,
  serviceAndSecurityValuesName,
  technologiesDescriptionName,
} from '../../../ProposalValues';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {
  accountingAttributesDescriptionName,
  alignSecureCodingPracticesName,
  dataAdminName,
  dataForwardedName,
  dataForwardedThirdPartyName,
  dataProtectionName,
  euDataName,
  externalServicesDescriptionName,
  freeTextRemarksDescriptionName,
  gdprCompliantName,
  hostCommandsDescriptionName,
  localDataName,
  newSabreRedTechnologiesDescriptionName,
  numberOfTransactionName,
  panRenderedName,
  patchTimelineName,
  pciAdditionalDetailsName,
  piiDataRetentionAdminName,
  piiDataRetentionName,
  protocolName,
  retentionPeriodName,
  ServiceAndSecurityValues,
  utilizedMethodName,
} from '../../serviceAndSecurity/ServiceAndSecurityValues';
import parse from 'html-react-parser';
import {
  IndentViewWrapper,
  replaceQuillIndentWithNestedLists
} from "@scm/components/text/textUtils";

const ReviewRow = ({name, isSecurity}: {name: keyof ProposalValues; isSecurity?: boolean}) => {
  const {watch} = useContext(ProposalContext);
  let value = isSecurity ? watch(serviceAndSecurityValuesName)[name as keyof ServiceAndSecurityValues] : watch(name);

  if (name === descriptionName) {
    value = replaceQuillIndentWithNestedLists(value);
  }

  const yesOrNo = (val: boolean) => {
    return <span>{val ? 'Yes' : 'No'}</span>;
  };

  enum Confirmation {
    'YES' = 'yes',
    'NO' = 'no',
  }

  const createContent = () => {
    const shouldParse = () => {
      return ![
        interactsWithEndTravelerDescriptionName,
        providePnrDocumentationDescriptionName,
        connectToGdsDescriptionName,
        technologiesDescriptionName,
        isPaidDescriptionName,
        hostCommandsDescriptionName,
        externalServicesDescriptionName,
        utilizedMethodName,
        newSabreRedTechnologiesDescriptionName,
        pciAdditionalDetailsName,
        panRenderedName,
        retentionPeriodName,
        dataAdminName,
        dataForwardedName,
        euDataName,
        gdprCompliantName,
        piiDataRetentionName,
        piiDataRetentionAdminName,
        localDataName,
        dataForwardedThirdPartyName,
        protocolName,
        accountingAttributesDescriptionName,
        dataProtectionName,
        alignSecureCodingPracticesName,
        patchTimelineName,
        freeTextRemarksDescriptionName,
        numberOfTransactionName,
      ].includes(name);
    };

    if (Array.isArray(value)) {
      const item = value[0];
      if (typeof item === 'string' || item instanceof String) {
        return value.join(', ');
      } else if (item === undefined) {
        return '';
      } else {
        return (item as File)?.name;
      }
    } else if (typeof value === 'boolean' || value === undefined) {
      if (name.toLowerCase() === 'isPaid'.toLowerCase()) {
        return yesOrNo(!!value);
      } else {
        return !!value ? yesOrNo(true) : '';
      }
    } else {
      if (typeof value === 'string') {
        const lowerCaseValue = value.toLowerCase();
        if (lowerCaseValue === Confirmation.YES) {
          return yesOrNo(true);
        } else if (lowerCaseValue === Confirmation.NO) {
          return '';
        } else {
          return shouldParse() ? parse(value) : value;
        }
      } else {
        return null;
      }
    }
  };
  const content = createContent();

  return content !== '' ? (
    <p className="spark-mar-b-1">
      <RowLabel>
        <FormattedMessage id={`review.${name}`} />
      </RowLabel>
      <ContentValue>
        <IndentViewWrapper>
          {content}
        </IndentViewWrapper>
      </ContentValue>
    </p>
  ) : null;
};

export const RowLabel = styled.strong`
  &::after {
    content: ':';
    display: inline;
    margin-left: 0.1rem;
    margin-right: 1rem;
  }
`;

const ContentValue = styled.span`
  word-break: break-word;
  font-family: Roboto Light, Sans-Serif, serif;

  & > p {
    margin-bottom: 0 !important;
  }
`;

export default ReviewRow;
