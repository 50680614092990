/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';
import type { ProposalStatus } from './ProposalStatus';
import {
    ProposalStatusFromJSON,
    ProposalStatusFromJSONTyped,
    ProposalStatusToJSON,
} from './ProposalStatus';
import type { ProvisioningType } from './ProvisioningType';
import {
    ProvisioningTypeFromJSON,
    ProvisioningTypeFromJSONTyped,
    ProvisioningTypeToJSON,
} from './ProvisioningType';

/**
 * 
 * @export
 * @interface SmallProposalResponse
 */
export interface SmallProposalResponse {
    /**
     * 
     * @type {string}
     * @memberof SmallProposalResponse
     */
    proposalId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallProposalResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SmallProposalResponse
     */
    accountManager?: string;
    /**
     * 
     * @type {ProductType}
     * @memberof SmallProposalResponse
     */
    productType?: ProductType;
    /**
     * 
     * @type {ProvisioningType}
     * @memberof SmallProposalResponse
     */
    provisioningType?: ProvisioningType;
    /**
     * 
     * @type {ProposalStatus}
     * @memberof SmallProposalResponse
     */
    status?: ProposalStatus;
    /**
     * Timestamp in ISO 8601 format.
     * @type {Date}
     * @memberof SmallProposalResponse
     */
    lastModifiedAt?: Date;
    /**
     * Timestamp in ISO 8601 format.
     * @type {Date}
     * @memberof SmallProposalResponse
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SmallProposalResponse
     */
    proposalSku?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmallProposalResponse
     */
    isFavourite?: boolean;
}

/**
 * Check if a given object implements the SmallProposalResponse interface.
 */
export function instanceOfSmallProposalResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SmallProposalResponseFromJSON(json: any): SmallProposalResponse {
    return SmallProposalResponseFromJSONTyped(json, false);
}

export function SmallProposalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmallProposalResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'accountManager': !exists(json, 'accountManager') ? undefined : json['accountManager'],
        'productType': !exists(json, 'productType') ? undefined : ProductTypeFromJSON(json['productType']),
        'provisioningType': !exists(json, 'provisioningType') ? undefined : ProvisioningTypeFromJSON(json['provisioningType']),
        'status': !exists(json, 'status') ? undefined : ProposalStatusFromJSON(json['status']),
        'lastModifiedAt': !exists(json, 'lastModifiedAt') ? undefined : (new Date(json['lastModifiedAt'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'proposalSku': !exists(json, 'proposalSku') ? undefined : json['proposalSku'],
        'isFavourite': !exists(json, 'isFavourite') ? undefined : json['isFavourite'],
    };
}

export function SmallProposalResponseToJSON(value?: SmallProposalResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposalId': value.proposalId,
        'name': value.name,
        'accountManager': value.accountManager,
        'productType': ProductTypeToJSON(value.productType),
        'provisioningType': ProvisioningTypeToJSON(value.provisioningType),
        'status': ProposalStatusToJSON(value.status),
        'lastModifiedAt': value.lastModifiedAt === undefined ? undefined : (value.lastModifiedAt.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'proposalSku': value.proposalSku,
        'isFavourite': value.isFavourite,
    };
}

