import React, {useState} from 'react';
import ContactDetails from './ContactDetails';
import EndMessage from '../../components/EndMessage';
import ErrorMessage from '../../components/ErrorMessage';
import TermsAndConditions from './TermsAndConditions';
import Pricing from './Pricing';
import ReviewAndSubmit from './ReviewAndSubmit';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {ProviderTypeValue} from '../../JoinUsContentBox';
import {
  CompanyYearsInBusinessType,
  NumberOfEmployeesType,
  OnboardDeveloperPartnerRequest,
} from '../../../generated/onboardings';

const DeveloperPartnerFormContent = ({
                                       currentStep,
                                       setCurrentStep,
                                       watch,
                                       setProviderTypeValue,
                                     }: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  watch: UseFormWatch<ProviderTypeValue>;
  setProviderTypeValue: UseFormSetValue<ProviderTypeValue>;
}) => {
  let result = null;

  const [onboardDeveloperPartnerRequest, setOnboardDeveloperPartnerRequest] = useState<OnboardDeveloperPartnerRequest>({
    contactDetails: {
      firstName: '',
      lastName: '',
      titleInCompany: '',
      email: '',
      phone: '',
    },
    companyInformation: {
      name: '',
      address: {
        line1: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
      },
      website: '',
      numberOfEmployees: NumberOfEmployeesType._110,
      yearsInBusiness: CompanyYearsInBusinessType._15,
      locationCountry: '',
      locationProvince: '',
      businessProfile: '',
    },
    sabreCustomerAccountInfo: {
      name: '',
      pcc: '',
    },
  });
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  switch (currentStep) {
    case 1:
      result = (
        <ContactDetails
          saveHandler={onboardDeveloperPartnerRequest =>
            setOnboardDeveloperPartnerRequest((prevState: OnboardDeveloperPartnerRequest) => ({
              ...prevState,
              ...onboardDeveloperPartnerRequest,
            }))
          }
          initValue={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          watchProviderTypeValue={watch}
          setProviderTypeValue={setProviderTypeValue}
          currentStep={currentStep}
        />
      );
      break;
    case 2:
      result = (
        <TermsAndConditions
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          acceptTermsAndConditions={acceptTermsAndConditions}
          setAcceptTermsAndConditions={setAcceptTermsAndConditions}
          isDeveloperPartnerForm={true}
          currentStep={currentStep}
        />
      );
      break;
    case 3:
      result = (
        <Pricing
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      );
      break;
    case 4:
      result = (
        <ReviewAndSubmit
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      );
      break;
    case 5:
      result = <EndMessage/>;
      break;
    case -1:
      result = <ErrorMessage/>;
      break;
  }

  return result;
};

export default DeveloperPartnerFormContent;
