import {FormProvider, Resolver, SubmitHandler, useForm, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {StyledH2} from "./ContactDetails";
import {FormattedMessage, useIntl} from "react-intl";
import {Text} from "@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage";
import React, {Dispatch, SetStateAction, useContext} from "react";
import {Fields} from "../../components/EndMessage";
import {OnboardDeveloperPartnerExtendedRequest} from "../../../generated/onboardings";
import useYupValidationResolver, {getFieldMaxLength, isFieldRequired} from "../../components/useYupValidationResolver";
import schema from "../../../onboarding-schema/additionalInfo.schema";
import {GaContext} from "../../../utils/gaContext";
import {onboardingDeveloperPartnerLatestStepName} from "../../JoinUsContentBox";
import {EventCategories} from "./TermsAndConditions";
import {buildGaActionLabel} from "@scm/components/ga/actions";
import {Button, Icon} from "@sabre/spark-react-core";
import {ButtonSize} from "@sabre/spark-react-core/types";
import SparkInput from "@scm/components/form/SparkInput";
import {DiagramForm, flowDiagramName} from "./DeveloperPartnerFormContent";
import {FileUpload, Star} from "@scm/components";
import {pdfFileType} from "@scm/components/utils/common";
import styled from "styled-components";
import colors from "@scm/components/assets/colors";

const MAX_FILES = 1;
const MAX_SIZE = 10485760;

const AdditionalInfo = ({
                          saveHandler,
                          onboardDeveloperPartnerRequest,
                          currentStep,
                          setCurrentStep,
                          watchDiagram,
                          setDiagram
                        }: {
  saveHandler: Dispatch<SetStateAction<OnboardDeveloperPartnerExtendedRequest>>;
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  watchDiagram: UseFormWatch<DiagramForm>;
  setDiagram: UseFormSetValue<DiagramForm>;
}) => {
  const {formatMessage} = useIntl();

  const resolver = useYupValidationResolver(schema);
  const methods = useForm<OnboardDeveloperPartnerExtendedRequest>({
    defaultValues: onboardDeveloperPartnerRequest,
    mode: 'onChange',
    resolver: resolver as Resolver<OnboardDeveloperPartnerExtendedRequest>,
  });

  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardDeveloperPartnerExtendedRequest> = (data: OnboardDeveloperPartnerExtendedRequest) => {
    saveHandler(data);
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
      logEvent({
        category: EventCategories.JoinUs,
        // TODO: TO confirm action
        action: buildGaActionLabel('ga.action.onboarding.movedToProjectScopeAi', 'ga.tag.partnerNetwork.developerPartner')
      });
      sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
    }
  };

  const previousStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  return (
    <FormProvider{...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.additionalInfo.title"/>
            </StyledH2>
            <Text>
              <FormattedMessage id="joinUsPage.form.additionalInfo.description"/>
            </Text>
            <Fields>
              <SparkInput
                name="projectScope.thirdPartyInterfaces"
                value={onboardDeveloperPartnerRequest.projectScope?.thirdPartyInterfaces ?? ''}
                label={formatMessage({id: 'joinUsPage.form.additionalInfo.thirdPartyInterfaces.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.additionalInfo.thirdPartyInterfaces.label'})}
                errorMessage={methods.formState.errors.projectScope?.thirdPartyInterfaces?.message}
                isRequired={isFieldRequired('projectScope.thirdPartyInterfaces', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.thirdPartyInterfaces', schema)}
                isMultiline
              />
              <div className="spark-mar-b-2">
                <p className="spark-mar-t-2 spark-mar-b-0 spark-caps">
                  <FormattedMessage id="joinUsPage.form.additionalInfo.diagram.label"/>
                  <Star/>
                </p>
                <FilesList className="spark-list--no-bullets spark-mar-l-0 spark-mar-b-0">
                  {watchDiagram("flowDiagram")[0] && (
                    <li
                      onClick={() => setDiagram(flowDiagramName, [])}
                      onKeyDown={evt => {
                        if (evt.key === 'Enter') {
                          setDiagram(flowDiagramName, [])
                        }
                      }}>
                      <span role="button" tabIndex={0}>
                        {watchDiagram(flowDiagramName)[0].name} <Icon name="trash"/>
                      </span>
                    </li>
                  )}
                </FilesList>
                <FileUpload
                  filesNames="flowDiagram"
                  setFiles={setDiagram as () => void}
                  watch={watchDiagram}
                  additionalText={formatMessage({
                    id: 'joinUsPage.form.additionalInfo.diagram.additionalText',
                  })}
                  maxFiles={MAX_FILES}
                  maxFileSize={MAX_SIZE}
                  filesType={pdfFileType}
                />
              </div>
              <SparkInput
                name="projectScope.comment"
                value={onboardDeveloperPartnerRequest.projectScope?.comment ?? ''}
                label={formatMessage({id: 'joinUsPage.form.additionalInfo.comment.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.additionalInfo.comment.label'})}
                errorMessage={methods.formState.errors.projectScope?.comment?.message}
                isRequired={isFieldRequired('projectScope.comment', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.comment', schema)}
                isMultiline
              />
            </Fields>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton"/>
          </Button>
          <Button
            type="submit"
            disabled={!methods.formState.isValid || !watchDiagram(flowDiagramName)[0]}
            size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button"/>
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

const FilesList = styled.ul`
  & .spark-icon {
    font-size: 1.8rem;
    vertical-align: -1px;
    color: ${colors.sparkBlue};
    cursor: pointer;
  }
`;

export default AdditionalInfo;
