import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormProvider, useForm, UseFormWatch} from 'react-hook-form';
import ReviewList from '../../components/reviewComponents/ReviewList';
import ReviewHeader from '../../components/reviewComponents/ReviewHeader';
import countries from '@scm/components/assets/pnCountries';
import {StyledH2} from './ContactDetails';
import {FullHeightContainer} from './Pricing';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {onboardingsApiBaseLink} from '../../../assets/apiBaseLink';
import {
  Capabilities,
  Configuration,
  DistributionType,
  OnboardDeveloperPartnerExtendedRequest,
  OnboardingsApi,
  RegionType,
  TechnicalSupportType,
} from '../../../generated/onboardings';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import ReviewParagraphs from '@scm/components/form/review/ReviewParagraphs';
import {EventCategories} from './TermsAndConditions';
import {GaContext} from '../../../utils/gaContext';
import {onboardingDeveloperPartnerLatestStepName} from '../../JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";
import {DiagramForm, flowDiagramName} from "./DeveloperPartnerFormContent";

export const postData = async (request: OnboardDeveloperPartnerExtendedRequest) => await new OnboardingsApi(
  new Configuration({
    basePath: onboardingsApiBaseLink,
  })
).onboardDeveloperPartnerExtended(request);


async function handleData(
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest,
  flowDiagram: File | undefined,
  successHandler: () => void,
  errorHandler: () => void
) {
  try {
    const mapped = JSON.parse(JSON.stringify(onboardDeveloperPartnerRequest)) as OnboardDeveloperPartnerExtendedRequest;
    delete mapped['isImplementationFee' as keyof typeof mapped];
    if (mapped.projectScope.generalInformation?.launchDate) {
      mapped.projectScope.generalInformation.launchDate = new Date(mapped.projectScope.generalInformation.launchDate);
    }
    const removeEmptyStringProperties = (obj: Record<string, any>): Record<string, any> => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== '')
      );
    }

    if (mapped.projectScope.contentSupplierSources) {
      mapped.projectScope.contentSupplierSources = removeEmptyStringProperties(mapped.projectScope.contentSupplierSources);
      if (Object.keys(mapped.projectScope.contentSupplierSources).length === 0) {
        delete mapped.projectScope.contentSupplierSources;
      }
    }

    if (mapped.projectScope.comment === '') {
      delete mapped.projectScope.comment;
    }

    if (flowDiagram) {
      mapped.flowDiagram = flowDiagram;
    }

    await postData(mapped);

    successHandler();
  } catch (e) {
    errorHandler();
  }
}

const ReviewAndSubmit = ({
                           onboardDeveloperPartnerRequest,
                           setCurrentStep,
                           currentStep,
                           watchDiagram
                         }: {
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  watchDiagram: UseFormWatch<DiagramForm>;
}) => {
  const {formatMessage} = useIntl();
  const methods = useForm({mode: 'onChange'});
  const {logEvent} = useContext(GaContext);

  const formSubmitHandler = async function (success: () => void, failure: () => void) {
    const successHandler = () => {
      success();
      setCurrentStep(prevState => prevState + 1);
      if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
        logEvent({
          category: EventCategories.JoinUs,
          action: buildGaActionLabel('ga.action.onboarding.proposalSubmitted', 'ga.tag.partnerNetwork.developerPartner')
        });
        sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
      }
    };

    const errorHandler = () => {
      failure();
      setCurrentStep(-1);
    };

    await handleData(
      onboardDeveloperPartnerRequest,
      watchDiagram(flowDiagramName)[0],
      successHandler,
      errorHandler);
  };

  const previousStep = () => setCurrentStep(prevState => prevState - 1);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.reviewAndSubmit.title"/>
            </StyledH2>
            <Text>
              <FormattedMessage id="joinUsPage.form.reviewAndSubmit.description"/>
            </Text>
            <FullHeightContainer>
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.generalInformation"/>
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.firstName.label',
                  'joinUsPage.form.contactDetails.lastName.label',
                  'joinUsPage.form.contactDetails.companyTitle.label',
                  'joinUsPage.form.contactDetails.email.label',
                  'joinUsPage.form.contactDetails.phone.label',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.contactDetails.firstName,
                  onboardDeveloperPartnerRequest.contactDetails.lastName,
                  onboardDeveloperPartnerRequest.contactDetails.titleInCompany,
                  onboardDeveloperPartnerRequest.contactDetails.email,
                  onboardDeveloperPartnerRequest.contactDetails.phone,
                ]}
              />
              <ReviewHeader content="joinUsPage.form.companyInformation.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.fullCompanyName.label',
                  'joinUsPage.form.contactDetails.url.label',
                  'joinUsPage.form.contactDetails.staff.label',
                  'joinUsPage.form.contactDetails.companyYears.label',
                  'joinUsPage.form.contactDetails.solutionDescription.placeholder',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.companyInformation.name,
                  onboardDeveloperPartnerRequest.companyInformation.website || '',
                  onboardDeveloperPartnerRequest.companyInformation.numberOfEmployees || '',
                  onboardDeveloperPartnerRequest.companyInformation.yearsInBusiness || '',
                  onboardDeveloperPartnerRequest.companyInformation.businessProfile,
                ]}
              />
              <ReviewHeader content="joinUsPage.form.companyLocation.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.countryCode.label',
                  'joinUsPage.form.contactDetails.provinceLocated.placeholder',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.companyInformation.locationCountry ?? '',
                  onboardDeveloperPartnerRequest.companyInformation.locationProvince ?? '',
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.companyAddress.title" type="h4"/>
              <ReviewParagraphs
                label={Object.keys(onboardDeveloperPartnerRequest.companyInformation.address).map(
                  item => `joinUsPage.form.contactDetails.${item}.label`
                )}
                content={Object.values(onboardDeveloperPartnerRequest.companyInformation.address)}
              />
              <ReviewHeader content="joinUsPage.form.contactDetails.potentialSabreCustomer.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.contactDetails.potentialSabreCustomer.name.label',
                  'joinUsPage.form.contactDetails.potentialSabreCustomer.pcc.label',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.sabreCustomerAccountInfo.name,
                  onboardDeveloperPartnerRequest.sabreCustomerAccountInfo.pcc,
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.termsAndCondition.title"/>
              <ReviewParagraphs label={['joinUsPage.form.reviewAndSubmit.termsAndCondition.description']}/>
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.pricingDetails.title"/>
              <ReviewParagraphs label={['joinUsPage.form.pricing.api.title']} content={[' ']} isListHeader/>
              <ReviewList
                items={[
                  `joinUsPage.form.reviewAndSubmit.pricingDetails.license.${
                    onboardDeveloperPartnerRequest.partnerPricingDetails?.technicalSupport ===
                    TechnicalSupportType.Unlimited
                      ? 'unlimited'
                      : 'paidPerIncident'
                  }.label`,
                  'joinUsPage.form.reviewAndSubmit.implementationFee.label',
                ]}
                isId
              />
              <ReviewParagraphs
                label={['joinUsPage.form.pricing.subscriptionFee.title']}
                content={[
                  `${onboardDeveloperPartnerRequest.partnerPricingDetails?.distributionType} Distribution${
                    onboardDeveloperPartnerRequest.partnerPricingDetails?.distributionType === DistributionType.Global
                      ? ' (USD 2,916.67 per month)'
                      : onboardDeveloperPartnerRequest.partnerPricingDetails?.distributionType ===
                      DistributionType.Market
                        ? ' (USD 416.67 per month)'
                        : ''
                  }`,
                ]}
                isListHeader
              />
              {onboardDeveloperPartnerRequest.partnerPricingDetails?.distributionType === DistributionType.Market && (
                <ReviewList
                  items={onboardDeveloperPartnerRequest.partnerPricingDetails?.countryCodes?.map(
                    item => (countries as { [key: string]: string })[item]
                  )}
                />
              )}
              {onboardDeveloperPartnerRequest.partnerPricingDetails?.distributionType === DistributionType.Regional && (
                <ReviewList
                  items={onboardDeveloperPartnerRequest.partnerPricingDetails?.regions?.map(
                    item => item + ` (USD ${regionsPrices[item]} per month)`
                  )}
                />
              )}
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.projectScope.title"/>
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.projectScope.generalInformation.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.reviewAndSubmit.projectScope.generalInformation.name',
                  'joinUsPage.form.reviewAndSubmit.projectScope.generalInformation.launchDate',
                  'joinUsPage.form.reviewAndSubmit.projectScope.generalInformation.targetMarket',
                  'joinUsPage.form.reviewAndSubmit.projectScope.generalInformation.description',
                  'joinUsPage.form.reviewAndSubmit.projectScope.generalInformation.capabilities',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.projectScope.generalInformation?.name ?? '',
                  onboardDeveloperPartnerRequest.projectScope.generalInformation?.launchDate as unknown as string ?? '',
                  onboardDeveloperPartnerRequest.projectScope.generalInformation?.targetMarket ?? '',
                  onboardDeveloperPartnerRequest.projectScope.generalInformation?.description ?? '',
                  onboardDeveloperPartnerRequest.projectScope.generalInformation?.capabilities === Capabilities.Sabre ?
                    formatMessage({id: 'joinUsPage.form.projectScope.general.capabilities.sabre'}) :
                    formatMessage({id: 'joinUsPage.form.projectScope.general.capabilities.multiGds'}
                    ),
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.projectScope.contentSupplierSources.title"
                            type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.reviewAndSubmit.projectScope.contentSupplierSources.car',
                  'joinUsPage.form.reviewAndSubmit.projectScope.contentSupplierSources.hotel',
                  'joinUsPage.form.reviewAndSubmit.projectScope.contentSupplierSources.connectivity',
                  'joinUsPage.form.reviewAndSubmit.projectScope.contentSupplierSources.air',
                  'joinUsPage.form.reviewAndSubmit.projectScope.contentSupplierSources.rail',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.car || '-',
                  onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.hotel || '-',
                  onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.connectivity || '-',
                  onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.air || '-',
                  onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.rail || '-',
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.projectScope.sabreInteractions.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.reviewAndSubmit.projectScope.sabreInteractions.sabreApi.label',
                  'joinUsPage.form.reviewAndSubmit.projectScope.sabreInteractions.keySabreContent.label',
                  'joinUsPage.form.reviewAndSubmit.projectScope.sabreInteractions.keySabreFunctionality.label',
                  'joinUsPage.form.reviewAndSubmit.projectScope.sabreInteractions.sabreScans.label',
                  'joinUsPage.form.reviewAndSubmit.projectScope.sabreInteractions.creditCardStorage.label',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.sabreApi ?? '',
                  onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.keySabreContent ?? '',
                  onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.keySabreFunctionality ?? '',
                  onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.sabreScans ?? '',
                  onboardDeveloperPartnerRequest.projectScope.sabreInteractions?.creditCardStorage ?? '',
                ]}
              />
              <ReviewHeader content="joinUsPage.form.reviewAndSubmit.projectScope.additionalInfo.title" type="h4"/>
              <ReviewParagraphs
                label={[
                  'joinUsPage.form.reviewAndSubmit.projectScope.thirdParty.title',
                  'joinUsPage.form.reviewAndSubmit.projectScope.diagram.title',
                  'joinUsPage.form.reviewAndSubmit.projectScope.comment.title',
                ]}
                content={[
                  onboardDeveloperPartnerRequest.projectScope?.thirdPartyInterfaces ?? '',
                  watchDiagram(flowDiagramName)[0]?.name ?? '',
                  onboardDeveloperPartnerRequest.projectScope?.comment || '-',
                ]}
              />
            </FullHeightContainer>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.backButton"/>
          </Button>
          <Button
            progress
            onClick={async (_, success, failure) => {
              if (success && failure) {
                await formSubmitHandler(success, failure);
              }
            }}
            size={ButtonSize.SMALL}
            data-testid="Submit"
          >
            <FormattedMessage id="joinUsPage.form.endButton"/>
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

const regionsPrices: { [key in RegionType]: string } = {
  APAC: '1,000',
  EMEA: '1,250',
  LAC: '583.34',
  NAM: '2,083.34',
};

export default ReviewAndSubmit;
