import React, {useEffect, useRef, useState} from 'react';
import DateInput from '@sabre/spark/js/src/components/date-input';
import CalendarPopover from '@sabre/spark/js/src/components/calendar-popover';
import styled from 'styled-components';
import color from '../assets/colors';
import {useIntl} from 'react-intl';
import {Star} from "../text/Star";

export const DatePicker = ({
                             changeHandler,
                             label,
                             value,
                             className,
                             readOnly,
                             dateFormat = 'DD/MM/YYYY',
                             isRequired,
                           }: {
  changeHandler: (value: string) => void;
  label: string;
  value?: string;
  className?: string;
  readOnly?: boolean;
  dateFormat?: string;
  isRequired?: boolean;
}) => {
  const datePickerRef = useRef(null);
  const inputRef = useRef(null);
  const {formatMessage} = useIntl();
  const [oldValue, setOldValue] = useState('');

  useEffect(() => {
    const sparkCalendarPopover = new CalendarPopover(datePickerRef.current);
    const sparkDatePicker = new DateInput(datePickerRef.current, {
      calendarEl: sparkCalendarPopover,
      onChange: readOnly ? () => {
      } : newValue => changeHandler(newValue),
      format: dateFormat.toUpperCase(),
    });

    return () => {
      sparkDatePicker.remove();
      sparkCalendarPopover.remove();
    };
  }, []);

  useEffect(() => {
    Array.from((datePickerRef.current as unknown as HTMLElement).querySelectorAll('.spark-input__field')).forEach(
      (input: Element) => ((input as HTMLInputElement).disabled = !!readOnly)
    );
  }, [readOnly]);

  useEffect(() => {
    if (datePickerRef.current) {
      const inputEl = inputRef.current as unknown as HTMLElement;
      if (inputEl) {
        if (oldValue !== (inputEl as HTMLInputElement).value) {
          const event = new Event('change');
          inputEl.dispatchEvent(event);
          setOldValue((inputEl as HTMLInputElement).value);
        }
      }
    }
  });

  return (
    <DateField className={`spark-center-block container ${className ?? ''}`}>
      <label className="spark-input spark-date" ref={datePickerRef}>
        <input
          ref={inputRef}
          className="spark-input__field"
          type="date"
          role="textbox"
          value={value}
          disabled={readOnly}
        />
        <span className="spark-label">
          {label}
          {isRequired && <Star/>}
        </span>
        <a
          tabIndex={0}
          className="spark-input__addon spark-date__calendar-toggle"
          aria-label={formatMessage({id: 'components.datePicker.ariaLabel'})}
          title="Show Calendar"
          role="button"
        >
          <i className="spark-icon-calendar spark-icon--md"/>
        </a>
      </label>
    </DateField>
  );
};

const DateField = styled.div`
  & .spark-input__placeholder,
  & .spark-label {
    color: ${color.disabledGrey} !important;
  }
`;
