/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContentSupplierSources } from './ContentSupplierSources';
import {
    ContentSupplierSourcesFromJSON,
    ContentSupplierSourcesFromJSONTyped,
    ContentSupplierSourcesToJSON,
} from './ContentSupplierSources';
import type { GeneralInformation } from './GeneralInformation';
import {
    GeneralInformationFromJSON,
    GeneralInformationFromJSONTyped,
    GeneralInformationToJSON,
} from './GeneralInformation';
import type { SabreInteractions } from './SabreInteractions';
import {
    SabreInteractionsFromJSON,
    SabreInteractionsFromJSONTyped,
    SabreInteractionsToJSON,
} from './SabreInteractions';

/**
 * 
 * @export
 * @interface ProjectScope
 */
export interface ProjectScope {
    /**
     * 
     * @type {GeneralInformation}
     * @memberof ProjectScope
     */
    generalInformation?: GeneralInformation;
    /**
     * 
     * @type {ContentSupplierSources}
     * @memberof ProjectScope
     */
    contentSupplierSources?: ContentSupplierSources;
    /**
     * 
     * @type {SabreInteractions}
     * @memberof ProjectScope
     */
    sabreInteractions?: SabreInteractions;
    /**
     * 
     * @type {string}
     * @memberof ProjectScope
     */
    thirdPartyInterfaces?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectScope
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectScope
     */
    sellerId?: string;
}

/**
 * Check if a given object implements the ProjectScope interface.
 */
export function instanceOfProjectScope(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectScopeFromJSON(json: any): ProjectScope {
    return ProjectScopeFromJSONTyped(json, false);
}

export function ProjectScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectScope {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generalInformation': !exists(json, 'generalInformation') ? undefined : GeneralInformationFromJSON(json['generalInformation']),
        'contentSupplierSources': !exists(json, 'contentSupplierSources') ? undefined : ContentSupplierSourcesFromJSON(json['contentSupplierSources']),
        'sabreInteractions': !exists(json, 'sabreInteractions') ? undefined : SabreInteractionsFromJSON(json['sabreInteractions']),
        'thirdPartyInterfaces': !exists(json, 'thirdPartyInterfaces') ? undefined : json['thirdPartyInterfaces'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'sellerId': !exists(json, 'sellerId') ? undefined : json['sellerId'],
    };
}

export function ProjectScopeToJSON(value?: ProjectScope | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generalInformation': GeneralInformationToJSON(value.generalInformation),
        'contentSupplierSources': ContentSupplierSourcesToJSON(value.contentSupplierSources),
        'sabreInteractions': SabreInteractionsToJSON(value.sabreInteractions),
        'thirdPartyInterfaces': value.thirdPartyInterfaces,
        'comment': value.comment,
        'sellerId': value.sellerId,
    };
}

