/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyInformation } from './CompanyInformation';
import {
    CompanyInformationFromJSON,
    CompanyInformationFromJSONTyped,
    CompanyInformationToJSON,
} from './CompanyInformation';
import type { ContactDetails } from './ContactDetails';
import {
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';
import type { PartnerPricingDetails } from './PartnerPricingDetails';
import {
    PartnerPricingDetailsFromJSON,
    PartnerPricingDetailsFromJSONTyped,
    PartnerPricingDetailsToJSON,
} from './PartnerPricingDetails';
import type { RedAppPricingType } from './RedAppPricingType';
import {
    RedAppPricingTypeFromJSON,
    RedAppPricingTypeFromJSONTyped,
    RedAppPricingTypeToJSON,
} from './RedAppPricingType';
import type { SabreAccountInfo } from './SabreAccountInfo';
import {
    SabreAccountInfoFromJSON,
    SabreAccountInfoFromJSONTyped,
    SabreAccountInfoToJSON,
} from './SabreAccountInfo';
import type { SabreCustomerAccountInfo } from './SabreCustomerAccountInfo';
import {
    SabreCustomerAccountInfoFromJSON,
    SabreCustomerAccountInfoFromJSONTyped,
    SabreCustomerAccountInfoToJSON,
} from './SabreCustomerAccountInfo';

/**
 * 
 * @export
 * @interface OnboardingRequestResponse
 */
export interface OnboardingRequestResponse {
    /**
     * UUID of created onboarding document
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    type?: OnboardingRequestResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    status?: OnboardingRequestResponseStatusEnum;
    /**
     * UUID of created proposal document
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    proposalId?: string;
    /**
     * 
     * @type {ContactDetails}
     * @memberof OnboardingRequestResponse
     */
    contactDetails?: ContactDetails;
    /**
     * 
     * @type {CompanyInformation}
     * @memberof OnboardingRequestResponse
     */
    companyInformation?: CompanyInformation;
    /**
     * 
     * @type {SabreAccountInfo}
     * @memberof OnboardingRequestResponse
     */
    sabreAccountInfo?: SabreAccountInfo;
    /**
     * 
     * @type {SabreCustomerAccountInfo}
     * @memberof OnboardingRequestResponse
     */
    sabreCustomerAccountInfo?: SabreCustomerAccountInfo;
    /**
     * 
     * @type {RedAppPricingType}
     * @memberof OnboardingRequestResponse
     */
    redAppPricingType?: RedAppPricingType | null;
    /**
     * 
     * @type {PartnerPricingDetails}
     * @memberof OnboardingRequestResponse
     */
    partnerPricingDetails?: PartnerPricingDetails;
    /**
     * Id of created seller in Salesforce
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    sellerId?: string;
    /**
     * Optional description of onboarding rejection reason
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    reasonRejected?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingRequestResponse
     */
    projectScopeId?: string;
}


/**
 * @export
 */
export const OnboardingRequestResponseTypeEnum = {
    RedAppProvider: 'Red App Provider',
    DeveloperPartner: 'Developer Partner'
} as const;
export type OnboardingRequestResponseTypeEnum = typeof OnboardingRequestResponseTypeEnum[keyof typeof OnboardingRequestResponseTypeEnum];

/**
 * @export
 */
export const OnboardingRequestResponseStatusEnum = {
    Submitted: 'Submitted',
    UnderReview: 'Under Review',
    Rejected: 'Rejected',
    Approved: 'Approved',
    Obsolete: 'Obsolete'
} as const;
export type OnboardingRequestResponseStatusEnum = typeof OnboardingRequestResponseStatusEnum[keyof typeof OnboardingRequestResponseStatusEnum];


/**
 * Check if a given object implements the OnboardingRequestResponse interface.
 */
export function instanceOfOnboardingRequestResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OnboardingRequestResponseFromJSON(json: any): OnboardingRequestResponse {
    return OnboardingRequestResponseFromJSONTyped(json, false);
}

export function OnboardingRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'contactDetails': !exists(json, 'contactDetails') ? undefined : ContactDetailsFromJSON(json['contactDetails']),
        'companyInformation': !exists(json, 'companyInformation') ? undefined : CompanyInformationFromJSON(json['companyInformation']),
        'sabreAccountInfo': !exists(json, 'sabreAccountInfo') ? undefined : SabreAccountInfoFromJSON(json['sabreAccountInfo']),
        'sabreCustomerAccountInfo': !exists(json, 'sabreCustomerAccountInfo') ? undefined : SabreCustomerAccountInfoFromJSON(json['sabreCustomerAccountInfo']),
        'redAppPricingType': !exists(json, 'redAppPricingType') ? undefined : RedAppPricingTypeFromJSON(json['redAppPricingType']),
        'partnerPricingDetails': !exists(json, 'partnerPricingDetails') ? undefined : PartnerPricingDetailsFromJSON(json['partnerPricingDetails']),
        'sellerId': !exists(json, 'sellerId') ? undefined : json['sellerId'],
        'reasonRejected': !exists(json, 'reasonRejected') ? undefined : json['reasonRejected'],
        'projectScopeId': !exists(json, 'projectScopeId') ? undefined : json['projectScopeId'],
    };
}

export function OnboardingRequestResponseToJSON(value?: OnboardingRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'proposalId': value.proposalId,
        'contactDetails': ContactDetailsToJSON(value.contactDetails),
        'companyInformation': CompanyInformationToJSON(value.companyInformation),
        'sabreAccountInfo': SabreAccountInfoToJSON(value.sabreAccountInfo),
        'sabreCustomerAccountInfo': SabreCustomerAccountInfoToJSON(value.sabreCustomerAccountInfo),
        'redAppPricingType': RedAppPricingTypeToJSON(value.redAppPricingType),
        'partnerPricingDetails': PartnerPricingDetailsToJSON(value.partnerPricingDetails),
        'sellerId': value.sellerId,
        'reasonRejected': value.reasonRejected,
        'projectScopeId': value.projectScopeId,
    };
}

