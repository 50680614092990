import * as yup from 'yup';
export default  yup.object().shape({
  projectScope: yup.object().shape({
    sabreInteractions: yup.object().shape({
      sabreApi: yup.string().min(1).max(1000).required('This field is required'),
      keySabreContent: yup.string().min(1).max(1000).required('This field is required'),
      keySabreFunctionality: yup.string().min(1).max(1000).required('This field is required'),
      sabreScans: yup.string().min(1).max(1000).required('This field is required'),
      creditCardStorage: yup.string().min(1).max(1000).required('This field is required'),
    }),
  }),
});
