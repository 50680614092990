/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Capabilities = {
    Sabre: 'Sabre',
    MultiGds: 'Multi GDS'
} as const;
export type Capabilities = typeof Capabilities[keyof typeof Capabilities];


export function CapabilitiesFromJSON(json: any): Capabilities {
    return CapabilitiesFromJSONTyped(json, false);
}

export function CapabilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Capabilities {
    return json as Capabilities;
}

export function CapabilitiesToJSON(value?: Capabilities | null): any {
    return value as any;
}

