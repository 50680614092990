const proposalDetails = {
  'proposalDetails.definition': 'Definition',
  'proposalDetails.status': 'Status',
  'proposalDetails.provider.name': 'Red App Provider',
  'proposalDetails.seller.name': 'Submitted by',
  'proposalDetails.proposalApproval': 'Proposal Approval',
  'proposalDetails.review': 'Review',
  'proposalDetails.approve': 'Approve',
  'proposalDetails.missingSeller.title': 'CC Seller not assigned.',
  'proposalDetails.missingSeller.message.line1':
    'This proposal cannot be approved until a CC Seller is assigned.',
  'proposalDetails.missingSeller.message.line2':
    'Review the proposal details and onboarding request (link button below)',
  'proposalDetails.missingSeller.message.line3': `If a new CC Seller needs to be created, use the "Create Seller" button in the onboarding request`,
  'proposalDetails.missingSeller.message.line4':
    'If an existing CC Seller should be assigned (e.g., the developer has downline products and a CC Seller), use the "Assign Existing Seller" button',
  'proposalDetails.missingSeller.message.line5':
    'To reject the proposal, reject the entire onboarding request (link button below)',
  'proposalDetails.missingSeller.message.lineMail': 'Ensure the assigned CC Seller has at least one Developer User assigned. To add a Developer User, contact {hrefMail}',
  'proposalDetails.missingSellerDevelopers.title': 'No Developer User assigned.',
  'proposalDetails.missingSellerDevelopers.message.line1':
    'This proposal cannot be approved yet - please make sure that assigned CC Seller has at least one Developer User.',
  'proposalDetails.reject': 'Reject',
  'proposalDetails.delete': 'Delete',
  'proposalDetails.onboardingRequest': 'Onboarding Request',
  'proposalDetails.redAppType': 'Red App Type',
  'proposalDetails.redAppName': 'Red App Name',
  'proposalDetails.description': 'Description',
  'proposalDetails.categories': 'Categories',
  'proposalDetails.categories.select': 'Please select category',
  'proposalDetails.flowDiagram': 'Red App Use Case Flow Diagram',
  'proposalDetails.flowDiagram.error': 'Flow Diagram cannot be downloaded',
  'proposalDetails.flowDiagram.errorTitle': 'Failed',
  'proposalDetails.download.flowDiagram': 'Download Flow Diagram',
  'proposalDetails.interactionWithEndTraveler': 'My Red App interacts with end traveler in any way',
  'proposalDetails.interactionWithEndTravelerComment': 'How will this app interact with the agency and/or traveler?',
  'proposalDetails.pnrDocumentation': 'My Red App provides/executes any PNR documentation',
  'proposalDetails.pnrDocumentationComment': 'Please describe how will this app provides/executes PNR documentation',
  'proposalDetails.connectionToGds': 'I connect to any GDS today',
  'proposalDetails.connectionToGdsComment': 'Please provide the details',
  'proposalDetails.developmentType': 'Development Type',
  'proposalDetails.technologies': 'Technologies used to build the Red App',
  'proposalDetails.regionalization': 'Regionalization',
  'proposalDetails.regionalization.label': 'Access limited to specific countries or/and regions',
  'proposalDetails.availableRegions': 'Available Regions',
  'proposalDetails.availableRegionsAndCountries': 'Available Regions & Countries',
  'proposalDetails.availableRegions.select': 'Please select available regions and countries',
  'proposalDetails.availableCountries': 'Available Countries',
  'proposalDetails.regionalConstraintsComment':
    'Please provide any additional information about your current customers, your target market, or any future markets, if applicable.',
  'proposalDetails.pricing': 'Pricing',
  'proposalDetails.isChargeable': 'My Red App is chargeable (paid)',
  'proposalDetails.pricing.comment':
    'Please provide the details what will be the payment model of this Red App (one-time fee, recurring, negotiable, per PCC, per EPR) ',
  'proposalDetails.services': 'Services',
  'proposalDetails.externalServicesComment':
    'Which External services (please be specific, e.g., Google Analytics, your own services etc.) would your Red App communicate to and why are they needed? ',
  'proposalDetails.authentication': 'What is the method utilized to authenticate service?',
  'proposalDetails.sabreServicesComment':
    'Please provide which Host commands, Sabre Web-Services, etc. will be used and why they are needed?',
  'proposalDetails.bargainFinderMax': 'If using APIs, does it connect to Bargain Finder Max?',
  'proposalDetails.sr360TechnologiesComment': 'What Sabre Red 360 technologies will your Red App be built with?',
  'proposalDetails.security': 'Security',
  'proposalDetails.pci.data': 'Please select all PCI data that Red App processes, stores or transmits',
  'proposalDetails.pci.utilizingThirdPartyProcessor':
    'If utilizing a third-party processor, are they PCI certified by an auditor?',
  'proposalDetails.pci.pan': 'Please describe how the PAN is rendered unreadable everywhere it is stored',
  'proposalDetails.pci.comment':
    'If necessary, please list additional details for truncation, masking or tokenization of PCI data',
  'proposalDetails.pci.retentionPeriod':
    'What will the retention period and purging process for any stored cardholder or personal identifying data be?',
  'proposalDetails.pci.usedBy': 'Who will process the data and how? Please list the names',
  'proposalDetails.pii.data': 'Please select all PII data that the Red App process stores or transmits',
  'proposalDetails.pii.howIsForwarded': 'How is the PII data forwarded (email, Web Services call, etc.)?',
  'proposalDetails.pii.euNationals':
    'Will the Red App store or process data for EU nationals? If so, will the data be stored or processed outside of the EU? Please list the names of locations that will be utilized for this app',
  'proposalDetails.pii.complianceWithGdpr':
    'Is the Red App compliant with GDPR? Please describe how compliance is managed',
  'proposalDetails.pii.retentionPeriod': 'How long will this data be stored? What’s the default data retention?',
  'proposalDetails.pii.piiUsedBy': 'Who can manage the data retention?',
  'proposalDetails.pii.sharedWithThirdParty':
    'Will any of the locally saved data be shared with a third-party service?',
  'proposalDetails.gds.gdsHowIsForwarded':
    'How is this forwarded (email, web services, call to third-party hosted system, other)?',
  'proposalDetails.gds.protocol': 'What is the protocol (https/http/sftp/VPN) between the client and third-party?',
  'proposalDetails.gds.protectionComment':
    'How will your Red App data be protected at rest and in the motion, both for PII and PCI data?',
  'proposalDetails.gds.gdsComment': 'Does it rely on a free text remarks field in the PNR?',
  'proposalDetails.gds.numberOfTransaction': 'Projected number of transactions to be processed (private use vs global)',
  'proposalDetails.gds.patchTimeline':
    'What is the default timeline for patching vulnerabilities discovered in the code by automated scanning tools or manual processes, identified as a high, medium or low risk?',
  'proposalDetails.accounting.dataType': 'Which element will be utilized (accounting or back-office data)',
  'proposalDetails.accounting.attributesDescription': 'Which specific data attributes which will be leveraged?',
  'proposalDetails.deleteProposalModal.title': 'Delete Proposal',
  'proposalDetails.deleteProposalModal.description':
    'Are you sure that you want to completely remove this proposal? This action is irreversible.',
  'proposalDetails.removeDraftModal.title': 'Remove Draft?',
  'proposalDetails.removeDraftModal.description':
    'Are you sure that you want to remove this proposal draft? If you delete it, you will no longer be able to retrieve the saved information.',
  'proposalDetails.removeModal.title': 'Remove Proposal?',
  'proposalDetails.removeModal.description':
    'Are you sure that you want to remove this proposal? If you delete it, you will no longer be able to retrieve the saved information.',
  'proposalDetails.approveModal.title': 'Approve Proposal',
  'proposalDetails.approveModal.description':
    'Are you sure that you want to approve this proposal? This will create product in SCM and allow user to submit version bundles.',
  'proposalDetails.rejectModal.title': 'Reject Proposal',
  'proposalDetails.rejectModal.description':
    'Are you sure that you want to reject this proposal? If so, in case you want to sent some details about the reason to developer, you can add it below (it will be added to email notification and displayed in proposal details).',
  'proposalDetails.rejectModal.reasonLabel': 'Reason of rejection',
  'proposalDetails.modal.deleteProposalButton': 'Delete',
  'proposalDetails.modal.removeButton': 'Remove',
  'proposalDetails.modal.cancelButton': 'Cancel',
  'proposalDetails.modal.approveButton': 'Approve',
  'proposalDetails.modal.rejectButton': 'Reject',
  'proposalDetails.changeStatus.message': 'Proposal status changed.',
  'proposalDetails.changeStatus.title': 'Success.',
  'proposalDetails.deleteProposal.message': 'Proposal {name} has been successfully deleted.',
  'approvedProposal.successTitle': 'Success.',
  'approvedProposal.errorTitle': 'Failure.',
  'approvedProposal.error': "Proposal can't be updated.",
  'approvedProposal.success': 'Proposal was updated.',
};

export default proposalDetails;
