import {Button} from "@sabre/spark-react-core";
import {ButtonSize} from "@sabre/spark-react-core/types";
import {FormattedMessage, useIntl} from "react-intl";
import React, {Dispatch, SetStateAction, useContext} from "react";
import {FormProvider, Resolver, SubmitHandler, useForm} from "react-hook-form";
import {Capabilities, OnboardDeveloperPartnerExtendedRequest} from "../../../generated/onboardings";
import useYupValidationResolver, {getFieldMaxLength, isFieldRequired} from "../../components/useYupValidationResolver";
import schema from "../../../onboarding-schema/projectScopeGeneral.schema";
import {onboardingDeveloperPartnerLatestStepName} from "../../JoinUsContentBox";
import {EventCategories} from "./TermsAndConditions";
import {buildGaActionLabel} from "@scm/components/ga/actions";
import {GaContext} from "../../../utils/gaContext";
import {Text} from "@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage";
import {StyledH2} from "./ContactDetails";
import {Fields} from "../../components/EndMessage";
import SparkInput from "@scm/components/form/SparkInput";
import RadioButtons from "@scm/components/form/RadioButtons";
import {RadioContainer} from "./Pricing";
import {DatePicker, Star} from "@scm/components";

const ProjectScopeGeneralInformation = ({saveHandler, onboardDeveloperPartnerRequest, currentStep, setCurrentStep}: {
  saveHandler: Dispatch<SetStateAction<OnboardDeveloperPartnerExtendedRequest>>;
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {formatMessage} = useIntl();

  const resolver = useYupValidationResolver(schema);

  const methods = useForm<OnboardDeveloperPartnerExtendedRequest>({
    defaultValues: onboardDeveloperPartnerRequest,
    mode: 'onChange',
    resolver: resolver as Resolver<OnboardDeveloperPartnerExtendedRequest>,
  });

  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardDeveloperPartnerExtendedRequest> = (data: OnboardDeveloperPartnerExtendedRequest) => {
    if (data.projectScope.generalInformation?.launchDate) {
      data.projectScope.generalInformation.launchDate = methods.getValues('projectScope.generalInformation.launchDate') as unknown as Date;
    }
    saveHandler(data);
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
      logEvent({
        category: EventCategories.JoinUs,
        // TODO: TO confirm action
        action: buildGaActionLabel('ga.action.onboarding.movedToProjectScopeGi', 'ga.tag.partnerNetwork.developerPartner')
      });
      sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
    }
  };

  const previousStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  return <FormProvider {...methods}>
    <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
      <div className="spark-panel">
        <div className="spark-panel__content">
          <StyledH2>
            <FormattedMessage id="joinUsPage.form.projectScope.general.title"/>
          </StyledH2>
          <Text>
            <FormattedMessage id="joinUsPage.form.projectScope.general.description"/>
          </Text>
          <Fields>
            <SparkInput
              name="projectScope.generalInformation.name"
              value={onboardDeveloperPartnerRequest.projectScope.generalInformation?.name ?? ''}
              label={formatMessage({id: 'joinUsPage.form.projectScope.general.name.label'})}
              placeholder={formatMessage({id: 'joinUsPage.form.projectScope.general.name.label'})}
              errorMessage={methods.formState.errors.projectScope?.generalInformation?.name?.message}
              isRequired={isFieldRequired('projectScope.generalInformation.name', schema)}
              hasCharacterCounter
              maxLength={getFieldMaxLength('projectScope.generalInformation.name', schema)}
            />
            <DatePicker
              label={formatMessage({id: 'joinUsPage.form.projectScope.general.launchDate.label'})}
              value={(methods.watch('projectScope.generalInformation.launchDate') || '') as string}
              changeHandler={(value: string) =>
                methods.setValue("projectScope.generalInformation.launchDate", value as unknown as Date, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true
                })
              }
              className="spark-mar-b-1 spark-pad-0"
              isRequired={isFieldRequired('projectScope.generalInformation.launchDate', schema)}
              dateFormat="YYYY-MM-DD"
            />
            <SparkInput
              name="projectScope.generalInformation.targetMarket"
              value={onboardDeveloperPartnerRequest.projectScope.generalInformation?.targetMarket ?? ''}
              label={formatMessage({id: 'joinUsPage.form.projectScope.general.targetMarket.label'})}
              placeholder={formatMessage({id: 'joinUsPage.form.projectScope.general.targetMarket.label'})}
              errorMessage={methods.formState.errors.projectScope?.generalInformation?.targetMarket?.message}
              isRequired={isFieldRequired('projectScope.generalInformation.targetMarket', schema)}
              hasCharacterCounter
              maxLength={getFieldMaxLength('projectScope.generalInformation.targetMarket', schema)}
              isMultiline
            />
            <SparkInput
              name="projectScope.generalInformation.description"
              value={onboardDeveloperPartnerRequest.projectScope.generalInformation?.description ?? ''}
              label={formatMessage({id: 'joinUsPage.form.projectScope.general.description.label'})}
              placeholder={formatMessage({id: 'joinUsPage.form.projectScope.general.description.label'})}
              errorMessage={methods.formState.errors.projectScope?.generalInformation?.description?.message}
              isRequired={isFieldRequired('projectScope.generalInformation.description', schema)}
              hasCharacterCounter
              maxLength={getFieldMaxLength('projectScope.generalInformation.description', schema)}
              isMultiline
            />
            <p className="spark-mar-b-1 spark-mar-t-2">
              <FormattedMessage id="joinUsPage.form.projectScope.general.capabilities.label"/>
              {isFieldRequired('projectScope.generalInformation.capabilities', schema) && <Star/>}
            </p>
            <RadioContainer>
              <RadioButtons
                name="projectScope.generalInformation.capabilities"
                values={Object.values(Capabilities)}
                labels={[
                  formatMessage({id: 'joinUsPage.form.projectScope.general.capabilities.sabre'}),
                  formatMessage({id: 'joinUsPage.form.projectScope.general.capabilities.multiGds'}),
                ]}
              />
            </RadioContainer>
          </Fields>
        </div>
      </div>
      <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
        <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
          <FormattedMessage id="joinUsPage.form.backButton"/>
        </Button>
        <Button
          type="submit"
          disabled={!methods.formState.isValid}
          size={ButtonSize.SMALL}>
          <FormattedMessage id="joinUsPage.form.button"/>
        </Button>
      </nav>
    </form>
  </FormProvider>
}

export default ProjectScopeGeneralInformation;
