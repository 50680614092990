enum InternalUrls {
  MyProducts = '/my-products',
  Home = '/',
  PartnerNetwork = '/partners',
  AdminCentre = '/admin-centre',
  Reports = '/reports',
  Resources = '/resources',
  Developers = '/developers',
  PartnerProfile = '/partner-profile',
  CertificationEngineerDashboard = '/certification-engineer-dashboard',
  SecurityDashboard = '/security-dashboard',
  Cleanup = '/cleanup',
  Users = '/users',
  FreezeCalendar = '/freeze-calendar',
  AddNewProduct = '/add-new-product',
  Proposal = '/proposal',
  Roadmap = '/roadmap',
  AuthCallback = '/authCallback',
  ProposalsCertification = '/proposals',
  ProposalsCertificationOnboardings = '/proposals/onboardingrequests',
  MockSsoLogin = '/mock-sso-login',
  AdminCentreMyProducts = '/admin-centre/my-products',
  NotificationSettings = '/admin-centre/user/notification-settings',
  ManageNotifications = '/manage-notifications',
  ManageCompany = '/manage-company',
  NotAuthorized = '/not-authorized',
  IncorrectProfile = '/incorrect-profile',
  Confirmations = '/confirmations',
  Error = '/error',
}

export default InternalUrls;
