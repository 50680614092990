import React, {useContext} from 'react';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {ProposalContext} from '../../pages/proposalForm/ProposalForm';
import {currentStepName} from '../../pages/proposalForm/ProposalValues';

export const ProposalItem = ({
  value,
  labelId,
  currentStep,
  setCurrentStep,
  isMultiline,
}: {
  value: number | string;
  labelId: string;
  currentStep: number;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  isMultiline?: boolean;
}) => {
  const {formatMessage} = useIntl();
  const {setValue} = useContext(ProposalContext);
  const classStep = (currentStep: number, number: number) => {
    let classStep = 'spark-step-indicator__item';
    if (currentStep === number) {
      classStep += ' spark-step-indicator__item--current';
    } else if (currentStep > number) {
      classStep += ' spark-step-indicator__item--completed';
    } else {
      classStep += ' spark-step-indicator__item--disabled';
    }
    return classStep;
  };

  const handleChangeStep = () => {
    const stepValue = +value;
    if (stepValue < currentStep) {
      if (setValue) {
        setValue(currentStepName, stepValue);
      } else if (setCurrentStep) {
        setCurrentStep(stepValue);
      }

      window.scrollTo(0, 0);
    }
  };

  return (
    <Container className={`${classStep(currentStep, +value)} ${isMultiline ? 'multiline' : ''}`} onClick={handleChangeStep}>
      <StepIcon>
        <StepNumber>{currentStep > value ? '' : value}</StepNumber>
      </StepIcon>
      <StepLabel>{formatMessage({id: labelId})}</StepLabel>
    </Container>
  );
};

const Container = styled.span.attrs(className => ({
  className: className,
}))`
  margin: 1rem 2rem;

  &.spark-step-indicator__item--completed .spark-step-indicator__icon {
    cursor: pointer !important;
  }

  &.spark-step-indicator__item::after {
    position: relative;
    top: -5rem;
    left: calc(50% + 3rem);
    width: calc(100% - 1rem);
    @media (max-width: 543px) {
      top: -1rem;
      left: 3rem;
      width: calc(100% - 1rem);
    }
  }

  &.spark-step-indicator__item.multiline::after {
    top: -7rem;
    @media (max-width: 543px) {
      top: -2rem;
    }
  }
`;

const StepIcon = styled.span.attrs(() => ({
  className: 'spark-step-indicator__icon',
}))`
  height: 4rem;
  width: 4rem;
`;

const StepNumber = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
`;

const StepLabel = styled.span.attrs(() => ({
  className: 'spark-step-indicator__text',
}))`
  width: 14rem;
  font-size: 1.3rem;
`;
