/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommentObject,
  CreateProposalResponse,
  FederationId,
  Proposal,
  ProposalNameRequest,
  ProposalOperationResponse,
  ProposalResponse,
  SmallProposalResponse,
} from '../models';
import {
    CommentObjectFromJSON,
    CommentObjectToJSON,
    CreateProposalResponseFromJSON,
    CreateProposalResponseToJSON,
    FederationIdFromJSON,
    FederationIdToJSON,
    ProposalFromJSON,
    ProposalToJSON,
    ProposalNameRequestFromJSON,
    ProposalNameRequestToJSON,
    ProposalOperationResponseFromJSON,
    ProposalOperationResponseToJSON,
    ProposalResponseFromJSON,
    ProposalResponseToJSON,
    SmallProposalResponseFromJSON,
    SmallProposalResponseToJSON,
} from '../models';

export interface AddAccountManagerRequest {
    id: string;
    federationId?: FederationId;
}

export interface AddProposalCommentRequest {
    id: string;
    commentObject: CommentObject;
}

export interface ApproveRequest {
    id: string;
}

export interface AssignUserToFavouriteProposalRequest {
    id: string;
}

export interface CheckNameRequest {
    name: string;
}

export interface CreateCcProductInSalesforceRequest {
    id: string;
}

export interface CreateDraftProposalRequest {
    proposal: Proposal;
    flowDiagram?: Blob;
}

export interface DeleteProposalRequest {
    id: string;
}

export interface DeleteUserFromFavouriteProposalRequest {
    id: string;
}

export interface DownloadFlowDiagramRequest {
    id: string;
}

export interface GetMissingItemsForApprovalRequest {
    id: string;
}

export interface GetProductSkuByProposalIdRequest {
    id: string;
}

export interface GetProductizedProposalRequest {
    sku: string;
}

export interface GetProposalRequest {
    id: string;
}

export interface HideProposalRequest {
    id: string;
}

export interface RejectRequest {
    id: string;
    reasonRejected?: string;
}

export interface ReviewRequest {
    id: string;
}

export interface SubmitRequest {
    id: string;
}

export interface UpdateDraftProposalRequest {
    id: string;
    proposal: Proposal;
    flowDiagram?: Blob;
}

export interface UpdateProposalRequest {
    id: string;
    proposal: Proposal;
    flowDiagram?: Blob;
}

export interface UpdateProposalNameRequest {
    id: string;
    proposalNameRequest?: ProposalNameRequest;
}

/**
 * 
 */
export class ProposalsApi extends runtime.BaseAPI {

    /**
     * Add account manager.
     */
    async addAccountManagerRaw(requestParameters: AddAccountManagerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addAccountManager.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/account-manager`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FederationIdToJSON(requestParameters.federationId),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add account manager.
     */
    async addAccountManager(requestParameters: AddAccountManagerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAccountManagerRaw(requestParameters, initOverrides);
    }

    /**
     * add comment to proposal
     */
    async addProposalCommentRaw(requestParameters: AddProposalCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addProposalComment.');
        }

        if (requestParameters.commentObject === null || requestParameters.commentObject === undefined) {
            throw new runtime.RequiredError('commentObject','Required parameter requestParameters.commentObject was null or undefined when calling addProposalComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentObjectToJSON(requestParameters.commentObject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * add comment to proposal
     */
    async addProposalComment(requestParameters: AddProposalCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProposalCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Change proposal status to approved used only by Red App Account Manager.
     */
    async approveRaw(requestParameters: ApproveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalOperationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling approve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/statuses/approved`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalOperationResponseFromJSON(jsonValue));
    }

    /**
     * Change proposal status to approved used only by Red App Account Manager.
     */
    async approve(requestParameters: ApproveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalOperationResponse> {
        const response = await this.approveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign a user who favoured/pinned the proposal
     */
    async assignUserToFavouriteProposalRaw(requestParameters: AssignUserToFavouriteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assignUserToFavouriteProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/favourites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign a user who favoured/pinned the proposal
     */
    async assignUserToFavouriteProposal(requestParameters: AssignUserToFavouriteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignUserToFavouriteProposalRaw(requestParameters, initOverrides);
    }

    /**
     * Check if provided name is valid and unique among existing products and proposals in status SUBMITTED or UNDER REVIEW. 
     */
    async checkNameRaw(requestParameters: CheckNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling checkName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposal-names-validations/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Check if provided name is valid and unique among existing products and proposals in status SUBMITTED or UNDER REVIEW. 
     */
    async checkName(requestParameters: CheckNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.checkNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create CC Product for proposal, which got approved, but has no SKU, ie. something went wrong with integration
     */
    async createCcProductInSalesforceRaw(requestParameters: CreateCcProductInSalesforceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createCcProductInSalesforce.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create CC Product for proposal, which got approved, but has no SKU, ie. something went wrong with integration
     */
    async createCcProductInSalesforce(requestParameters: CreateCcProductInSalesforceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCcProductInSalesforceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Draft Proposal for: * Developer Admin * Developer User * Site Admin 
     */
    async createDraftProposalRaw(requestParameters: CreateDraftProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateProposalResponse>> {
        if (requestParameters.proposal === null || requestParameters.proposal === undefined) {
            throw new runtime.RequiredError('proposal','Required parameter requestParameters.proposal was null or undefined when calling createDraftProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.proposal !== undefined) {
            formParams.append('proposal', new Blob([JSON.stringify(ProposalToJSON(requestParameters.proposal))], { type: "application/json", }));
                    }

        if (requestParameters.flowDiagram !== undefined) {
            formParams.append('flowDiagram', requestParameters.flowDiagram as any);
        }

        const response = await this.request({
            path: `/draft-proposals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProposalResponseFromJSON(jsonValue));
    }

    /**
     * Create Draft Proposal for: * Developer Admin * Developer User * Site Admin 
     */
    async createDraftProposal(requestParameters: CreateDraftProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateProposalResponse> {
        const response = await this.createDraftProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes proposal with given id * available only for: Red App Account Developer, Sabre Admin * applicable for proposals in DRAFT, REJECTED, SUBMITTED and UNDER REVIEW status 
     */
    async deleteProposalRaw(requestParameters: DeleteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes proposal with given id * available only for: Red App Account Developer, Sabre Admin * applicable for proposals in DRAFT, REJECTED, SUBMITTED and UNDER REVIEW status 
     */
    async deleteProposal(requestParameters: DeleteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProposalRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a user from favoured/pinned proposal
     */
    async deleteUserFromFavouriteProposalRaw(requestParameters: DeleteUserFromFavouriteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserFromFavouriteProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/favourites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a user from favoured/pinned proposal
     */
    async deleteUserFromFavouriteProposal(requestParameters: DeleteUserFromFavouriteProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserFromFavouriteProposalRaw(requestParameters, initOverrides);
    }

    /**
     * Download Flow Diagram file
     */
    async downloadFlowDiagramRaw(requestParameters: DownloadFlowDiagramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadFlowDiagram.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/flow-diagram`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download Flow Diagram file
     */
    async downloadFlowDiagram(requestParameters: DownloadFlowDiagramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFlowDiagramRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get missing items for proposal approval
     */
    async getMissingItemsForApprovalRaw(requestParameters: GetMissingItemsForApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMissingItemsForApproval.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/missing-items-for-approval`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get missing items for proposal approval
     */
    async getMissingItemsForApproval(requestParameters: GetMissingItemsForApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getMissingItemsForApprovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Product SKU from Proposal by ProposalId
     */
    async getProductSkuByProposalIdRaw(requestParameters: GetProductSkuByProposalIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductSkuByProposalId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/sku`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get Product SKU from Proposal by ProposalId
     */
    async getProductSkuByProposalId(requestParameters: GetProductSkuByProposalIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getProductSkuByProposalIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get productized proposal.
     */
    async getProductizedProposalRaw(requestParameters: GetProductizedProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalResponse>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getProductizedProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/productized-proposals/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalResponseFromJSON(jsonValue));
    }

    /**
     * Get productized proposal.
     */
    async getProductizedProposal(requestParameters: GetProductizedProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalResponse> {
        const response = await this.getProductizedProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get proposal.
     */
    async getProposalRaw(requestParameters: GetProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalResponseFromJSON(jsonValue));
    }

    /**
     * Get proposal.
     */
    async getProposal(requestParameters: GetProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalResponse> {
        const response = await this.getProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all proposals: * without drafts for Red App Account Manager * only for Partners assigned for Developers and Admin Users 
     */
    async getProposalsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SmallProposalResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SmallProposalResponseFromJSON));
    }

    /**
     * Get all proposals: * without drafts for Red App Account Manager * only for Partners assigned for Developers and Admin Users 
     */
    async getProposals(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SmallProposalResponse>> {
        const response = await this.getProposalsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Hide proposal (for developers) with given id * available only for: DEVELOPER, DEVELOPER ADMIN * applicable for proposals in PROPOSAL SUBMITTED and PROPOSAL REJECTED status 
     */
    async hideProposalRaw(requestParameters: HideProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hideProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Hide proposal (for developers) with given id * available only for: DEVELOPER, DEVELOPER ADMIN * applicable for proposals in PROPOSAL SUBMITTED and PROPOSAL REJECTED status 
     */
    async hideProposal(requestParameters: HideProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.hideProposalRaw(requestParameters, initOverrides);
    }

    /**
     * Change proposal status to rejected used only by Red App Account Manager.
     */
    async rejectRaw(requestParameters: RejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalOperationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reject.');
        }

        const queryParameters: any = {};

        if (requestParameters.reasonRejected !== undefined) {
            queryParameters['reasonRejected'] = requestParameters.reasonRejected;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/statuses/rejected`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalOperationResponseFromJSON(jsonValue));
    }

    /**
     * Change proposal status to rejected used only by Red App Account Manager.
     */
    async reject(requestParameters: RejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalOperationResponse> {
        const response = await this.rejectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change proposal status to under review used only by Red App Account Manager.
     */
    async reviewRaw(requestParameters: ReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalOperationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling review.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/statuses/review`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalOperationResponseFromJSON(jsonValue));
    }

    /**
     * Change proposal status to under review used only by Red App Account Manager.
     */
    async review(requestParameters: ReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalOperationResponse> {
        const response = await this.reviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change draft proposal status to submitted by Developer Admin and User.
     */
    async submitRaw(requestParameters: SubmitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalOperationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/draft-proposals/{id}/statuses/submitted`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalOperationResponseFromJSON(jsonValue));
    }

    /**
     * Change draft proposal status to submitted by Developer Admin and User.
     */
    async submit(requestParameters: SubmitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalOperationResponse> {
        const response = await this.submitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Proposal for Developer Admin and Developer User
     */
    async updateDraftProposalRaw(requestParameters: UpdateDraftProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDraftProposal.');
        }

        if (requestParameters.proposal === null || requestParameters.proposal === undefined) {
            throw new runtime.RequiredError('proposal','Required parameter requestParameters.proposal was null or undefined when calling updateDraftProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.proposal !== undefined) {
            formParams.append('proposal', new Blob([JSON.stringify(ProposalToJSON(requestParameters.proposal))], { type: "application/json", }));
                    }

        if (requestParameters.flowDiagram !== undefined) {
            formParams.append('flowDiagram', requestParameters.flowDiagram as any);
        }

        const response = await this.request({
            path: `/draft-proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Proposal for Developer Admin and Developer User
     */
    async updateDraftProposal(requestParameters: UpdateDraftProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDraftProposalRaw(requestParameters, initOverrides);
    }

    /**
     * Update Proposal for Red App Account Manager
     */
    async updateProposalRaw(requestParameters: UpdateProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProposal.');
        }

        if (requestParameters.proposal === null || requestParameters.proposal === undefined) {
            throw new runtime.RequiredError('proposal','Required parameter requestParameters.proposal was null or undefined when calling updateProposal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.proposal !== undefined) {
            formParams.append('proposal', new Blob([JSON.stringify(ProposalToJSON(requestParameters.proposal))], { type: "application/json", }));
                    }

        if (requestParameters.flowDiagram !== undefined) {
            formParams.append('flowDiagram', requestParameters.flowDiagram as any);
        }

        const response = await this.request({
            path: `/proposals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Proposal for Red App Account Manager
     */
    async updateProposal(requestParameters: UpdateProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProposalRaw(requestParameters, initOverrides);
    }

    /**
     * Update proposal name to new value.
     */
    async updateProposalNameRaw(requestParameters: UpdateProposalNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProposalName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/{id}/name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProposalNameRequestToJSON(requestParameters.proposalNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update proposal name to new value.
     */
    async updateProposalName(requestParameters: UpdateProposalNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProposalNameRaw(requestParameters, initOverrides);
    }

}
