/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SabreInteractions
 */
export interface SabreInteractions {
    /**
     * 
     * @type {string}
     * @memberof SabreInteractions
     */
    sabreApi?: string;
    /**
     * 
     * @type {string}
     * @memberof SabreInteractions
     */
    keySabreContent?: string;
    /**
     * 
     * @type {string}
     * @memberof SabreInteractions
     */
    keySabreFunctionality?: string;
    /**
     * 
     * @type {string}
     * @memberof SabreInteractions
     */
    sabreScans?: string;
    /**
     * 
     * @type {string}
     * @memberof SabreInteractions
     */
    creditCardStorage?: string;
}

/**
 * Check if a given object implements the SabreInteractions interface.
 */
export function instanceOfSabreInteractions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SabreInteractionsFromJSON(json: any): SabreInteractions {
    return SabreInteractionsFromJSONTyped(json, false);
}

export function SabreInteractionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SabreInteractions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sabreApi': !exists(json, 'sabreApi') ? undefined : json['sabreApi'],
        'keySabreContent': !exists(json, 'keySabreContent') ? undefined : json['keySabreContent'],
        'keySabreFunctionality': !exists(json, 'keySabreFunctionality') ? undefined : json['keySabreFunctionality'],
        'sabreScans': !exists(json, 'sabreScans') ? undefined : json['sabreScans'],
        'creditCardStorage': !exists(json, 'creditCardStorage') ? undefined : json['creditCardStorage'],
    };
}

export function SabreInteractionsToJSON(value?: SabreInteractions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sabreApi': value.sabreApi,
        'keySabreContent': value.keySabreContent,
        'keySabreFunctionality': value.keySabreFunctionality,
        'sabreScans': value.sabreScans,
        'creditCardStorage': value.creditCardStorage,
    };
}

