export const bundle = {
  'newBundle.title': 'Submit a Bundle for',
  'newBundle.mfa.message.cert': ` Please <a href="https://accounts.cert.havail.sabre.com/account/mfa" target="_blank">follow the instruction</a> to enable multi-factor authentication and sign in again to be able to submit new version.`,
  'newBundle.mfa.message.prod': ` Please <a href="https://accounts.havail.sabre.com/account/mfa" target="_blank">follow the instruction</a> to enable multi-factor authentication and sign in again to be able to submit new version.`,
  'newBundle.mfa.title': 'Multi-factor authentication is not enabled',
  'newBundle.initialDescription.label': 'Sabre Red 360 Description',
  'newBundle.initialDescription.comment': 'Text will be used in the Sabre Red 360 Agency Admin Tool only.',
  'newBundle.upload.header': 'Upload your Red App bundle',
  'newBundle.upload.additionalText': 'Upload *.zip file up to 32MB.',
  'newBundle.upload.previousVersionFile': 'Use file submitted with previous version ({version}).',
  'newBundle.upload.downloadFile': 'Download file.',
  'newBundle.info.listItem1':
    'If you upload a subsequent version of an existing Red App, be aware of the importance of the version number.',
  'newBundle.info.listItem2': 'The version number format is: major.minor.service.qualifier.',
  'newBundle.info.listItem3': 'If you increment the service or qualifier number, it is a patch.',
  'newBundle.info.listItem4':
    'Users of the prior version will receive this new patch automatically after it is processed successfully.',
  'newBundle.info.listItem5': 'If you increment the major or minor number, it is an upgrade.',
  'newBundle.info.listItem6':
    'For a new Red App or an upgrade to an existing Red App, you must submit a proposal to obtain a new Red App ID.',
  'newBundle.useCases.header': 'Upload a file that contains detailed use cases for all the functionality in your app',
  'newBundle.useCases.additionalText': 'Upload *.doc, *.docx or *.pdf file up to 10MB.',
  'newBundle.patchNote.label': 'Patch Release Notes',
  'newBundle.patchNote.placeholder': 'Describe changes in new version.',
};
