import React, {useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormProvider, Resolver, useForm} from 'react-hook-form';
import parse from 'html-react-parser';
import useYupValidationResolver from '../../components/useYupValidationResolver';
import schema from '../../../onboarding-schema/onboardDeveloperPartner.schema';
import {ExpandPanel} from '@scm/components';
import {Button} from '@sabre/spark-react-core';
import {StyledH2} from './ContactDetails';
import {Fields} from '../../components/EndMessage';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import Input from '@scm/components/form/SparkInput';
import countries from '@scm/components/assets/pnCountries';
import Checkbox from '@scm/components/form/Checkbox';
import FormFooter from '../../components/FormFooter';
import RadioButtons from '@scm/components/form/RadioButtons';
import MultiselectSpark from '@scm/components/form/MultiselectSpark';
import {ButtonSize} from '@sabre/spark-react-core/types';
import ToggleSwitch from '@scm/components/form/ToggleSwitch';
import {
  DistributionType,
  OnboardDeveloperPartnerExtendedRequest,
  RegionType,
  TechnicalSupportType,
} from '../../../generated/onboardings';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import {EventCategories} from './TermsAndConditions';
import {GaContext} from '../../../utils/gaContext';
import {onboardingDeveloperPartnerLatestStepName} from '../../JoinUsContentBox';
import {buildGaActionLabel} from "@scm/components/ga/actions";

interface PricingState extends OnboardDeveloperPartnerExtendedRequest {
  isMarketDistribution: boolean;
  searchText: string;
}

const Pricing = ({
  savePartner,
  onboardDeveloperPartnerRequest,
  setCurrentStep,
  currentStep,
}: {
  savePartner: React.Dispatch<React.SetStateAction<OnboardDeveloperPartnerExtendedRequest>>;
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
}) => {
  const resolver = useYupValidationResolver(
    schema
      .pick(['pcc', 'technicalSupport', 'distributionType', 'regions', 'countryCodes'])
      .test('test', (values: OnboardDeveloperPartnerExtendedRequest) => {
        return (
          !!values.partnerPricingDetails?.technicalSupport &&
          ((values.partnerPricingDetails?.distributionType === DistributionType.Regional &&
            (values.partnerPricingDetails?.regions || []).length > 0) ||
            (values.partnerPricingDetails?.distributionType === DistributionType.Market &&
              (values.partnerPricingDetails?.countryCodes || []).length > 0) ||
            values.partnerPricingDetails?.distributionType === DistributionType.Global)
        );
      })
  );
  const methods = useForm<PricingState>({
    mode: 'onChange',
    defaultValues: {
      partnerPricingDetails: {
        technicalSupport: onboardDeveloperPartnerRequest.partnerPricingDetails?.technicalSupport,
        distributionType: onboardDeveloperPartnerRequest.partnerPricingDetails?.distributionType,
        countryCodes: onboardDeveloperPartnerRequest.partnerPricingDetails?.countryCodes ?? [],
        regions: onboardDeveloperPartnerRequest.partnerPricingDetails?.regions ?? [],
      },
      isMarketDistribution: !!(onboardDeveloperPartnerRequest.partnerPricingDetails?.countryCodes || []).length,
    },
    resolver: resolver as Resolver<PricingState>,
  });

  const {formatMessage} = useIntl();
  const {logEvent} = useContext(GaContext);

  const formSubmitHandler = (data: PricingState) => {
    const {isMarketDistribution, searchText, ...rest} = data;
    savePartner(rest);
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
      logEvent({category: EventCategories.JoinUs, action: buildGaActionLabel('ga.action.onboarding.movedToReviewAndSubmit', 'ga.tag.partnerNetwork.developerPartner')});
      sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
    }
  };

  const previousStep = () => setCurrentStep(prevState => prevState - 1);

  const regions = methods.register('partnerPricingDetails.regions');
  const isMarketDistribution = methods.register('isMarketDistribution');
  const writeMarkets = () =>
    (methods.watch('partnerPricingDetails.countryCodes') || [])
      .slice(0, 2)
      .map((market: string) => (countries as {[key: string]: string})[market])
      .join(' | ');

  const [selectedRegionsCounter, setSelectedRegionsCounter] = useState(
    methods.getValues('partnerPricingDetails.regions')?.length ?? 0
  );

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.pricing.title" />
            </StyledH2>
            <Text data-margin-sm>
              <FormattedMessage id="joinUsPage.form.pricing.description" />
            </Text>
            <FullHeightContainer>
              <Fields>
                <SectionSeparator data-margin-sm>
                  {parse(formatMessage({id: 'joinUsPage.form.pricing.api.toggle.title'}))}
                </SectionSeparator>
                <p className="spark-mar-b-1">
                  <FormattedMessage id="joinUsPage.form.pricing.api.toggle.description" />
                </p>
                <ToggleSwitch
                  labels={[
                    <>
                      <Header>
                        {parse(formatMessage({id: 'joinUsPage.form.pricing.api.licence.unlimited.header'}))}
                      </Header>
                      <Content>
                        {parse(formatMessage({id: 'joinUsPage.form.pricing.api.licence.unlimited.content'}))}
                      </Content>
                    </>,
                    <>
                      <Header>
                        {parse(formatMessage({id: 'joinUsPage.form.pricing.api.licence.perIncident.header'}))}
                      </Header>
                      <Content>
                        {parse(formatMessage({id: 'joinUsPage.form.pricing.api.licence.perIncident.content'}))}
                      </Content>
                    </>,
                  ]}
                  name="partnerPricingDetails.technicalSupport"
                  values={Object.values(TechnicalSupportType)}
                />
                <RadioContainer>
                  <RadioButtons
                    name="isImplementationFee"
                    values={['Yes']}
                    labels={[parse(formatMessage({id: 'joinUsPage.form.api.implementationFee.label'}))]}
                    checked
                    disabled
                  />
                </RadioContainer>
                <SectionSeparator data-margin-sm>
                  <strong>
                    <FormattedMessage id="joinUsPage.form.pricing.subscriptionFee.title" />
                  </strong>
                  <StyledHeaderAdditionalText>
                    <FormattedMessage id="joinUsPage.form.pricing.subscriptionFee.title.additional" />
                  </StyledHeaderAdditionalText>
                </SectionSeparator>
                <DescriptionContainer>
                  {formatMessage({id: 'joinUsPage.form.pricing.subscriptionFee.description'})}
                </DescriptionContainer>
                <div className="row">
                  <input
                    type="text"
                    style={{display: 'none'}}
                    {...methods.register('partnerPricingDetails.distributionType')}
                    required
                  />
                  <CheckboxesColumn className="col-xs-4">
                    {parse(formatMessage({id: 'joinUsPage.form.pricing.subscriptionFee.globalDistribution.header'}))}
                    <Checkbox
                      testId="Global"
                      label={formatMessage({id: 'joinUsPage.form.pricing.subscriptionFee.globalDistribution.label'})}
                      onChange={e => {
                        const isGlobal = e.target.checked;
                        if (isGlobal) {
                          methods.setValue('partnerPricingDetails.distributionType', DistributionType.Global, {
                            shouldValidate: true,
                          });
                        } else {
                          methods.setValue('partnerPricingDetails.distributionType', '' as DistributionType, {
                            shouldValidate: true,
                          });
                        }
                      }}
                      disabled={
                        methods.watch('partnerPricingDetails.distributionType') === 'Regional' ||
                        methods.watch('partnerPricingDetails.distributionType') === 'Market'
                      }
                      isChecked={methods.watch('partnerPricingDetails.distributionType') === 'Global'}
                    />
                  </CheckboxesColumn>
                  <CheckboxesColumn className="col-xs-4">
                    {parse(formatMessage({id: 'joinUsPage.form.pricing.subscriptionFee.regionalDistribution.header'}))}
                    {Object.values(RegionType).map(item => (
                      <Checkbox
                        id={item}
                        key={item}
                        label={formatMessage({
                          id: `joinUsPage.form.pricing.subscriptionFee.regionalDistribution.${item}.label`,
                        })}
                        {...regions}
                        value={item}
                        onChange={e => {
                          regions.onChange(e).then(() => {
                            const selectedRegions = methods.getValues('partnerPricingDetails.regions') || [];
                            if (selectedRegions.length > 0) {
                              methods.setValue('partnerPricingDetails.distributionType', DistributionType.Regional, {
                                shouldValidate: true,
                              });
                            } else {
                              methods.setValue('partnerPricingDetails.distributionType', '' as DistributionType, {
                                shouldValidate: true,
                              });
                              methods.setValue('partnerPricingDetails.regions', [], {shouldValidate: true});
                            }

                            setSelectedRegionsCounter(selectedRegions.length);
                          });
                        }}
                        disabled={
                          methods.watch('partnerPricingDetails.distributionType') === 'Global' ||
                          methods.watch('partnerPricingDetails.distributionType') === 'Market' ||
                          (selectedRegionsCounter === 3 &&
                            !(methods.watch('partnerPricingDetails.regions') || []).includes(item))
                        }
                      />
                    ))}
                  </CheckboxesColumn>
                  <CheckboxesColumn className="col-xs-4">
                    {parse(formatMessage({id: 'joinUsPage.form.pricing.subscriptionFee.marketDistribution.header'}))}
                    <Checkbox
                      {...isMarketDistribution}
                      label={formatMessage({id: 'joinUsPage.form.pricing.subscriptionFee.marketDistribution.label'})}
                      onChange={e => {
                        isMarketDistribution.onChange(e).then(() => {
                          const isMarket = e.target.checked;
                          if (isMarket) {
                            methods.setValue('partnerPricingDetails.distributionType', DistributionType.Market, {
                              shouldValidate: true,
                            });
                          } else {
                            methods.setValue('partnerPricingDetails.distributionType', '' as DistributionType, {
                              shouldValidate: true,
                            });
                            methods.setValue('partnerPricingDetails.countryCodes', [], {shouldValidate: true});
                          }
                        });
                      }}
                      disabled={
                        methods.watch('partnerPricingDetails.distributionType') === 'Global' ||
                        methods.watch('partnerPricingDetails.distributionType') === 'Regional'
                      }
                    />
                    <div style={methods.watch('isMarketDistribution') ? {display: 'block'} : {display: 'none'}}>
                      <ExpandPanel
                        panelHeader={formatMessage({
                          id: 'joinUsPage.form.pricing.subscriptionFee.marketDistribution.expandPanel.header',
                        })}
                      >
                        <AutoSearchMultiSelect>
                          <Input
                            name="searchText"
                            value=""
                            label={formatMessage({
                              id: `joinUsPage.form.pricing.subscriptionFee.marketDistribution.search.label`,
                            })}
                            placeholder={formatMessage({
                              id: 'joinUsPage.form.pricing.subscriptionFee.marketDistribution.search.placeholder',
                            })}
                            icon
                          />
                          <MultiselectSpark
                            {...methods.register('partnerPricingDetails.countryCodes')}
                            searchText={methods.watch('searchText')?.toLowerCase()}
                            options={Object.keys(countries)
                              .filter(key => !(key.includes('US') || key.includes('CA')))
                              .reduce((obj, key) => {
                                (obj as {[key: string]: string})[key] = (countries as {[key: string]: string})[key];
                                return obj;
                              }, {})}
                            disabled={methods.getValues('partnerPricingDetails.countryCodes')?.length === 2}
                            markedValues={methods.getValues('partnerPricingDetails.countryCodes') as string[]}
                            hideErrorMessage
                          />
                          <ResultMarketContainer>{writeMarkets()}</ResultMarketContainer>
                        </AutoSearchMultiSelect>
                      </ExpandPanel>
                    </div>
                  </CheckboxesColumn>
                </div>
              </Fields>
            </FullHeightContainer>
          </div>
          <FormFooter
            listItemsContent={[1, 2, 3, 4].map(item =>
              parse(formatMessage({id: `joinUsPage.form.pricing.footer.item${item}`}))
            )}
          />
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton" />
          </Button>
          <Button type="submit" disabled={!methods.formState.isValid} size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button" />
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

export const FullHeightContainer = styled.div`
  min-height: 100vh;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SectionSeparator = styled.p`
  margin-top: 2rem;
  &[data-margin-sm] {
    margin-bottom: 1rem;
  }
`;

export const Header = styled.span`
  background-color: ${colors.highlightBlue200};
  color: ${colors.black};
  input[disabled] ~ .spark-label & {
    background-color: ${colors.grey100};
    color: ${colors.black};
  }
  padding: 1rem 2rem;
  flex-basis: 50%;
  width: 100%;
  .spark-toggle .spark-toggle__input:checked ~ .spark-label & {
    background-color: ${colors.grey700};
    color: ${colors.white};
  }
  .spark-toggle .spark-toggle__input[disabled]:checked ~ .spark-label & {
    background-color: ${colors.grey100};
    color: ${colors.black};
  }
  & span {
    display: block;
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
  }
`;

export const Content = styled.span`
  background-color: ${colors.white};
  color: ${colors.black};
  padding: 1rem;
  flex-basis: 50%;
  & span {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
  }
`;

export const StyledHeaderAdditionalText = styled.span`
  font-size: 1.25rem;
  vertical-align: 0.1rem;
  margin-left: 0.5rem;
`;

export const DescriptionContainer = styled.p`
  min-width: 1048px;
  @media (max-width: 1048px) {
    min-width: 0;
  }
`;

export const CheckboxesColumn = styled.div`
  display: flex;
  flex-direction: column;
  & .spark-checkbox {
    margin-top: 1rem;
  }
`;

export const ResultMarketContainer = styled.div`
  text-align: center;
  line-height: 300%;
  border-top: 1px solid ${colors.grey150};
`;

export const AutoSearchMultiSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 1px);
  & * {
    margin: 0;
    border-radius: 0;
  }
  & > label {
    width: 100%;
  }
  & .spark-input {
    border-bottom: none;
  }

  & .spark-multi-select {
    border: none;
    margin: 0 auto;
    transform: scaleX(0.99) translateX(-1.5px);
  }
`;

export default Pricing;
