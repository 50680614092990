import React, {Dispatch, SetStateAction, useContext} from "react";
import {FormProvider, Resolver, SubmitHandler, useForm} from "react-hook-form";
import {StyledH2} from "./ContactDetails";
import {FormattedMessage, useIntl} from "react-intl";
import {Text} from "@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage";
import {Button} from "@sabre/spark-react-core";
import {ButtonSize} from "@sabre/spark-react-core/types";
import {OnboardDeveloperPartnerExtendedRequest} from "../../../generated/onboardings";
import useYupValidationResolver, {getFieldMaxLength, isFieldRequired} from "../../components/useYupValidationResolver";
import schema from "../../../onboarding-schema/contentSupplierSources.schema";
import {GaContext} from "../../../utils/gaContext";
import {onboardingDeveloperPartnerLatestStepName} from "../../JoinUsContentBox";
import {EventCategories} from "./TermsAndConditions";
import {buildGaActionLabel} from "@scm/components/ga/actions";
import {Fields} from "../../components/EndMessage";
import SparkInput from "@scm/components/form/SparkInput";

const ContentSupplierSources = ({saveHandler, onboardDeveloperPartnerRequest, currentStep, setCurrentStep}: {
  saveHandler: Dispatch<SetStateAction<OnboardDeveloperPartnerExtendedRequest>>;
  onboardDeveloperPartnerRequest: OnboardDeveloperPartnerExtendedRequest;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {formatMessage} = useIntl();

  const resolver = useYupValidationResolver(schema);

  const methods = useForm<OnboardDeveloperPartnerExtendedRequest>({
    defaultValues: onboardDeveloperPartnerRequest,
    mode: 'onChange',
    resolver: resolver as Resolver<OnboardDeveloperPartnerExtendedRequest>,
  });

  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardDeveloperPartnerExtendedRequest> = (data: OnboardDeveloperPartnerExtendedRequest) => {
    saveHandler(data);
    setCurrentStep(prevState => prevState + 1);
    if ((sessionStorage.getItem(onboardingDeveloperPartnerLatestStepName) ?? 0) < currentStep) {
      logEvent({
        category: EventCategories.JoinUs,
        // TODO: TO confirm action
        action: buildGaActionLabel('ga.action.onboarding.movedToProjectScopeCs', 'ga.tag.partnerNetwork.developerPartner')
      });
      sessionStorage.setItem(onboardingDeveloperPartnerLatestStepName, currentStep.toString());
    }
  };

  const previousStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.contentSupplierSources.title"/>
            </StyledH2>
            <Text>
              <FormattedMessage id="joinUsPage.form.contentSupplierSources.description"/>
            </Text>
            <Fields>
              <SparkInput
                name="projectScope.contentSupplierSources.car"
                value={onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.car ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.car.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.car.label'})}
                errorMessage={methods.formState.errors.projectScope?.contentSupplierSources?.car?.message}
                isRequired={isFieldRequired('projectScope.contentSupplierSources.car', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.contentSupplierSources.car', schema)}
                isMultiline
              />
              <SparkInput
                name="projectScope.contentSupplierSources.hotel"
                value={onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.hotel ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.hotel.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.hotel.label'})}
                errorMessage={methods.formState.errors.projectScope?.contentSupplierSources?.hotel?.message}
                isRequired={isFieldRequired('projectScope.contentSupplierSources.hotel', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.contentSupplierSources.hotel', schema)}
                isMultiline
              />
              <SparkInput
                name="projectScope.contentSupplierSources.connectivity"
                value={onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.connectivity ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.connectivity.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.connectivity.label'})}
                errorMessage={methods.formState.errors.projectScope?.contentSupplierSources?.connectivity?.message}
                isRequired={isFieldRequired('projectScope.contentSupplierSources.connectivity', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.contentSupplierSources.connectivity', schema)}
                isMultiline
              />
              <SparkInput
                name="projectScope.contentSupplierSources.air"
                value={onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.air ?? ''}
                label={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.air.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.air.label'})}
                errorMessage={methods.formState.errors.projectScope?.contentSupplierSources?.air?.message}
                isRequired={isFieldRequired('projectScope.contentSupplierSources.air', schema) || undefined}
                hasCharacterCounter
                maxLength={getFieldMaxLength('projectScope.contentSupplierSources.air', schema)}
                isMultiline
              />
              <SparkInput
              name="projectScope.contentSupplierSources.rail"
              value={onboardDeveloperPartnerRequest.projectScope.contentSupplierSources?.rail ?? ''}
              label={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.rail.label'})}
              placeholder={formatMessage({id: 'joinUsPage.form.projectScope.contentSupplierSources.rail.label'})}
              errorMessage={methods.formState.errors.projectScope?.contentSupplierSources?.rail?.message}
              isRequired={isFieldRequired('projectScope.contentSupplierSources.rail', schema) || undefined}
              hasCharacterCounter
              maxLength={getFieldMaxLength('projectScope.contentSupplierSources.rail', schema)}
              isMultiline
            />
            </Fields>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton"/>
          </Button>
          <Button
            type="submit"
            disabled={!methods.formState.isValid}
            size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button"/>
          </Button>
        </nav>
      </form>
    </FormProvider>
  )
}

export default ContentSupplierSources;
