import {
  CcrzEPageLabelCSObject,
  CcrzEProductCSObjectProvisioningTypeCEnum,
  CcrzEProductCSObjectRedAppTypeCEnum,
  CcrzEProductCSObjectSabrePricingTypeCEnum,
  CcrzEProductMediaCSObject,
  CcrzEProductMediaCSObjectCcrzMediaTypeCEnum,
  Product,
} from '../../generated/product';
import {Confirmation, ProductDetailsI, ProductMedia, YtLinkI} from '../../pages/storefrontData/productDetailsUtils';
import {ProductCategories} from '@scm/components/assets/productCategories';
import {Product as SmallProduct} from '../../pages/myProducts/MyProducts';
import {SmallProductResponse} from '../../generated/presentation';
import HtmlToText from 'html-to-text';

const determineProductType = (productItem: SmallProductResponse) => {
  if (productItem.recordType === 'Red App') {
    if (productItem.redAppType === 'Regular') {
      return `${productItem.provisioningType} Red App`;
    } else {
      return `${productItem.redAppType}`;
    }
  } else {
    return productItem.recordType;
  }
};

export const productMapper = (product: Product): ProductDetailsI => ({
  id: product.ccrzEProductC?.id ?? '',
  redAppId: product.ccrzEProductC?.redAppIdC ?? '',
  sellerId: product.ccrzEProductC?.developerC ?? '',
  productName: product.ccrzEProductC?.name ?? '',
  productIcon: product?.ccrzEProductC?.logoURIC ?? '',
  type: CcrzEProductCSObjectProvisioningTypeCEnum[product.ccrzEProductC?.provisioningTypeC ?? 'Public'],
  updatedAt: '2099-05-14T11:11:21Z', //? TODO
  published: product.ccrzEProductC?.ccrzProductStatusC == 'Released',
  status: product.ccrzEProductC?.ccrzProductStatusC ?? '',
  mainPluginId: '####', //TODO
  proposalDate: '2099-05-14T11:11:21Z',
  category: getCategory(product),
  confirmationRequired: product.ccrzEProductC?.sellerConfirmationRequiredToOrderC ? Confirmation.Yes : Confirmation.No,
  storefrontDescription: parseRichText(product.ccrzEProductC?.descriptionR?.ccrzValueRTC ?? ''),
  storefrontShortDescription: shortDescriptionMapper(product?.ccrzEProductC?.ccrzShortDescRTC ?? ''),
  featuresDescription: '',
  keyBenefitsDescription: '',
  supportDescription: '',
  additionalInformationDescription: '',
  termsAndConditionsDescription: parseRichText(product.ccrzETermC?.termsAndConditionsR?.ccrzValueRTC ?? ''),
  ytLink: getYouTubeLink(product?.ccrzEProductMediaC ?? []) ?? '',
  respondMessage: parseRichText(product.ccrzEProductC?.providerMessageC ?? ''),
  imageFiles: getStorefrontImages(product?.ccrzEProductMediaC ?? []) ?? [],
  additionalFiles: getAdditionalFiles(product?.ccrzEProductMediaC ?? []) ?? [],
  pdfFiles: [],
  countryCodes: product.ccrzEProductC?.orderingAvailableInCountriesC?.split(',') ?? [],
  regionCodes: product.ccrzEProductC?.orderingAvailableInRegionsC?.split(',') ?? [],
  sku: product.ccrzEProductC?.redAppIdC ?? '',
  isPaid: product.ccrzEProductC?.sabrePricingTypeC ?? CcrzEProductCSObjectSabrePricingTypeCEnum.Paid,
  hideProduct: product.ccrzEProductC?.sabreHideProductC ? Confirmation.Yes : Confirmation.No,
  blockOrdering: product.ccrzEProductC?.blockOrderingC ? Confirmation.Yes : Confirmation.No,
  blockOrderingDescription: getBlockOrderingDescription(product.ccrzEProductC?.descriptionWhenOrderingBlockedR),
  redAppType: CcrzEProductCSObjectRedAppTypeCEnum[product.ccrzEProductC?.redAppTypeC ?? 'Regular'],
  referenceGuideUrl: product.ccrzEProductC?.quickReferenceGuideC,
});

const shortDescriptionMapper = (shortDesc: string) => (shortDesc.startsWith('<p>') ? shortDesc : `<p>${shortDesc}</p>`);

export const mapProductResponseToProductList = (product: SmallProductResponse[]): Array<SmallProduct> =>
  product.map(productItem => ({
    id: productItem.sku ?? '',
    productName: productItem.name ?? '',
    productIcon: productItem.logoUrl ?? '',
    type: determineProductType(productItem) ?? '',
    updatedAt: productItem.lastModifiedDate?.toISOString() ?? '',
    productStatus: productItem.productStatus,
    version: productItem.latestVersion ?? '',
    isLatestVersionPatch: productItem.isLatestVersionPatch ?? false,
    status: productItem.status,
    sku: productItem.sku ?? '',
    isFavourite: productItem?.isFavourite ?? '',
    isDownline: productItem?.isDownline ?? '',
  }));

const parseRichText = (val: string) =>
  val?.replaceAll('&quot;', '"')?.replaceAll('&#39;', "'").replaceAll('<p><br></p>', '');

export const parseToPlainText = (val: string) => HtmlToText.htmlToText(val).replace(/\s+/g, ' ').trim();

const getCategory = (product: Product) => {
  const categories = product.ccrzEProductCategoryC?.map(p => p.ccrzCategoryR?.name) ?? [];
  return categories.map(cat => ProductCategories[cat?.replaceAll('_', ' ') as keyof typeof ProductCategories]);
};

const getYouTubeLink = (mediaList: CcrzEProductMediaCSObject[]): YtLinkI => {
  let ytLink: YtLinkI = {} as YtLinkI;
  const link: CcrzEProductMediaCSObject = mediaList.filter(
    media => media.ccrzMediaTypeC === CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.YouTubeVideo
  )[0];
  if (link) {
    ytLink = {url: link.ccrzURIC as string, id: link.id as string};
  }
  return ytLink;
};

const getBlockOrderingDescription = (
  descriptionObject?: CcrzEPageLabelCSObject
): CcrzEPageLabelCSObject | undefined => {
  if (descriptionObject !== undefined) {
    descriptionObject.ccrzValueRTC = parseRichText(HtmlToText.htmlToText(descriptionObject?.ccrzValueRTC ?? ''));
  }
  return descriptionObject;
};

const getStorefrontImages = (mediaList: CcrzEProductMediaCSObject[]): ProductMedia[] => {
  const productMedias: ProductMedia[] = [];
  mediaList
    .filter(media => media.ccrzMediaTypeC === CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.StorefrontImage)
    .forEach(media =>
      productMedias.push({
        id: media.id as string,
        mediaType: media.ccrzMediaTypeC as CcrzEProductMediaCSObjectCcrzMediaTypeCEnum,
        uri: media.ccrzURIC as string,
        name: media.ccrzFilePathC ?? '',
      })
    );
  return productMedias;
};

const getAdditionalFiles = (mediaList: CcrzEProductMediaCSObject[]): ProductMedia[] => {
  const productMedias: ProductMedia[] = [];
  mediaList
    .filter(media => media.ccrzMediaTypeC === CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.Documents)
    .forEach(media =>
      productMedias.push({
        id: media.id as string,
        mediaType: media.ccrzMediaTypeC as CcrzEProductMediaCSObjectCcrzMediaTypeCEnum,
        uri: media.ccrzURIC as string,
        name: media.ccrzFilePathC ?? '',
      })
    );
  return productMedias;
};
