import {
  connectToGdsDescriptionName,
  countryCodesName,
  currentStepName,
  descriptionName,
  developmentTypeName,
  interactsWithEndTravelerDescriptionName,
  isPaidDescriptionName,
  proposalTypeName,
  ProposalValues,
  providePnrDocumentationDescriptionName,
  providerName,
  redAppCategoryName,
  redAppName,
  regionalizationDescriptionName,
  regionCodesName,
  serviceAndSecurityValuesName,
  technologiesDescriptionName,
  technologiesName,
} from '../ProposalValues';
import {
  accountingAttributesDescriptionName,
  accountingDataTypeName,
  bargainFinderMaxName,
  Confirmation,
  dataAdminName,
  dataForwardedName,
  dataForwardedThirdPartyName,
  dataProtectionName,
  euDataName,
  externalServicesDescriptionName,
  freeTextRemarksName,
  freeTextRemarksDescriptionName,
  gdprCompliantName,
  hostCommandsDescriptionName,
  localDataName,
  newSabreRedTechnologiesDescriptionName,
  numberOfTransactionName,
  panRenderedName,
  patchTimelineName,
  pciAdditionalDetailsName,
  pciCertifiedName,
  pciDataName,
  piiDataName,
  piiDataRetentionAdminName,
  piiDataRetentionName,
  protocolName,
  retentionPeriodName,
  utilizedMethodName,
} from '../steps/serviceAndSecurity/ServiceAndSecurityValues';
import {TechnologiesType} from '../steps/definitionPage/definitionComponents/Technologies';
import {createCountriesWithoutRegions} from '@scm/components/assets/regionsAndCountries';
import {richTextShortener} from '@scm/components/text/richTextShortener';
import {formatRichTextToSf} from "@scm/components/text/textUtils";

const productType = 'Red App';

export const proposalMapper = (proposal: ProposalValues) => ({
  name: proposal[redAppName],
  sellerId: proposal[providerName],
  provisioningType: getFirstWord(proposal[proposalTypeName]),
  proposalStep: proposal[currentStepName],
  productInfo: {
    productType,
    general: {
      description: formatRichTextToSf(proposal[descriptionName]),
      shortDescription: richTextShortener(proposal[descriptionName]),
      categories: proposal[redAppCategoryName],
      interactionWithEndTravelerComment: replaceFalsyWithNull(proposal[interactsWithEndTravelerDescriptionName]),
      pnrDocumentationComment: replaceFalsyWithNull(proposal[providePnrDocumentationDescriptionName]),
      developmentType: getFirstWord(proposal[developmentTypeName]),
      technologies: replaceOtherTechnology(proposal[technologiesName], proposal),
    },
    pricing: proposal[isPaidDescriptionName]
      ? {
        comment: proposal[isPaidDescriptionName],
      }
      : null,
    regionalization: {
      availableRegions: setAvailableRegionsValue(proposal[regionCodesName], proposal[countryCodesName].length),
      availableCountries: replaceFalsyWithNull(
        createCountriesWithoutRegions(proposal[regionCodesName], proposal[countryCodesName])
      ),
      regionalConstraintsComment: replaceFalsyWithNull(proposal[regionalizationDescriptionName]),
    },
    security: {
      pci: emptyObjectToNull(
        {
          data: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][pciDataName]),
          panProtectionComment: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][panRenderedName]),
          retentionPeriod: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][retentionPeriodName]),
          usedBy: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][dataAdminName]),
          utilizingThirdPartyProcessor: mapRadioToBoolean(proposal[serviceAndSecurityValuesName][pciCertifiedName]),
          comment: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][pciAdditionalDetailsName]),
        },
        true
      ),
      pii: emptyObjectToNull({
        data: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][piiDataName]),
        howIsForwarded: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][dataForwardedName]),
        euNationals: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][euDataName]),
        complianceWithGdpr: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][gdprCompliantName]),
        retentionPeriod: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][piiDataRetentionName]),
        usedBy: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][piiDataRetentionAdminName]),
        sharedWithThirdParty: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][localDataName]),
      }),
      protectionComment: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][dataProtectionName]),
      patchTimeline: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][patchTimelineName]),
      gds: emptyObjectToNull(
        {
          howIsForwarded: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][dataForwardedThirdPartyName]),
          protocol: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][protocolName]),
          numberOfTransaction: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][numberOfTransactionName]),
          comment: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][freeTextRemarksDescriptionName]),
          connectionToGdsComment: replaceFalsyWithNull(proposal[connectToGdsDescriptionName]),
          relyOnFreeTextRemarksInPnr: mapRadioToBoolean(proposal[serviceAndSecurityValuesName][freeTextRemarksName]),
        },
        true
      ),
      accounting: emptyObjectToNull({
        dataType: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][accountingDataTypeName]),
        attributesDescription: replaceFalsyWithNull(
          proposal[serviceAndSecurityValuesName][accountingAttributesDescriptionName]
        ),
      }),
    },
    services: {
      externalServices: emptyObjectToNull({
        comment: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][externalServicesDescriptionName]),
        authentication: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][utilizedMethodName]),
      }),
      sabreServicesComment: replaceFalsyWithNull(proposal[serviceAndSecurityValuesName][hostCommandsDescriptionName]),
      useBargainFinderMax: mapRadioToBoolean(proposal[serviceAndSecurityValuesName][bargainFinderMaxName]),
      sr360TechnologiesComment: replaceFalsyWithNull(
        proposal[serviceAndSecurityValuesName][newSabreRedTechnologiesDescriptionName]
      ),
    },
  },
});

const setAvailableRegionsValue = <T>(value: T, countriesLength: number) => {
  if (Array.isArray(value)) {
    return value.length || countriesLength ? value : ['Global'];
  }

  return value || null;
};

const replaceFalsyWithNull = <T>(value: T) => {
  if (Array.isArray(value)) {
    return value.length ? value : null;
  }

  return value || null;
};
const getFirstWord = (value: string | undefined) => value?.split(' ')[0];
const replaceOtherTechnology = (value: Array<string>, proposal: ProposalValues) => {
  const technologies = [...value];
  if (technologies.includes(TechnologiesType.Other)) {
    technologies.splice(technologies.indexOf(TechnologiesType.Other), 1, proposal[technologiesDescriptionName] || '');
  }

  return technologies;
};

const mapRadioToBoolean = (value: string) => {
  if (value === '' || value === undefined) {
    return null;
  }
  return value === Confirmation.Yes;
};

const checkIfObjectEmpty = <T extends Object>(value: T, saveFalseValue?: boolean) =>
  Object.values(value).every(o => {
    if (saveFalseValue) {
      return o === null;
    }
    return o === null || o === false;
  });

const emptyObjectToNull = <T extends Object>(value: T, saveFalseValue?: boolean) => {
  return checkIfObjectEmpty(value, saveFalseValue) ? null : value;
};
