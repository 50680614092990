import * as yup from 'yup';
export default  yup.object().shape({
  projectScope: yup.object().shape({
    contentSupplierSources: yup.object().shape({
      car: yup.string().max(1000),
      hotel: yup.string().max(1000),
      connectivity: yup.string().max(1000),
      air: yup.string().max(1000),
      rail: yup.string().max(1000),
    }),
  }),
});
