import React from 'react';
import InfoContent from './InfoContent';

export interface TextItems {
  standardInfo: string[];
  data?: string[];
  noHeading?: boolean;
}

const InfoColumn = ({textItems}: {textItems: TextItems}) => {
  return (
    <div className="col-xs-12 spark-mar-1">
      <InfoContent textItems={textItems} />
    </div>
  );
};

export default InfoColumn;
