import * as yup from 'yup';

export default  yup.object().shape({
  projectScope: yup.object().shape({
    generalInformation: yup.object().shape({
      name: yup.string().min(3).max(50).required('This field is required'),
      launchDate: yup.date().min(1).required('Launch date is required'),
      targetMarket: yup.string().min(1).max(1000).required('This field is required'),
      description: yup.string().min(150).max(1000).required('This field is required'),
      capabilities: yup.string().required('Capabilities are required'),
    }),
  }),
});
