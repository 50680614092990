import React, {useState} from 'react';
import ContactDetails from './ContactDetails';
import EndMessage from '../../components/EndMessage';
import ErrorMessage from '../../components/ErrorMessage';
import TermsAndConditions from './TermsAndConditions';
import Pricing from './Pricing';
import ReviewAndSubmit from './ReviewAndSubmit';
import {useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {ProviderTypeValue} from '../../JoinUsContentBox';
import {
  CompanyYearsInBusinessType,
  NumberOfEmployeesType,
  OnboardDeveloperPartnerExtendedRequest,
} from '../../../generated/onboardings';
import ProjectScopeGeneralInformation from "./ProjectScopeGeneralInformation";
import ContentSupplierSources from "./ContentSupplierSources";
import SabreInteractions from "./SabreInteractions";
import AdditionalInfo from "./AdditionalInfo";

export const flowDiagramName = 'flowDiagram';
export interface DiagramForm {
  [flowDiagramName]: File[];
}

const DeveloperPartnerFormContent = ({
                                       currentStep,
                                       setCurrentStep,
                                       watch,
                                       setProviderTypeValue,
                                     }: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  watch: UseFormWatch<ProviderTypeValue>;
  setProviderTypeValue: UseFormSetValue<ProviderTypeValue>;
}) => {
  let result = null;

  const [onboardDeveloperPartnerRequest, setOnboardDeveloperPartnerRequest] = useState<OnboardDeveloperPartnerExtendedRequest>({
    contactDetails: {
      firstName: '',
      lastName: '',
      titleInCompany: '',
      email: '',
      phone: '',
    },
    companyInformation: {
      name: '',
      address: {
        line1: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
      },
      website: '',
      numberOfEmployees: NumberOfEmployeesType._110,
      yearsInBusiness: CompanyYearsInBusinessType._15,
      locationCountry: '',
      locationProvince: '',
      businessProfile: '',
    },
    sabreCustomerAccountInfo: {
      name: '',
      pcc: '',
    },
    projectScope: {}
  });

  const {watch: watchDiagram, setValue: setDiagram} = useForm<DiagramForm>({
    mode: 'onChange',
    defaultValues: {
      [flowDiagramName]: []
    },
  });

  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);

  switch (currentStep) {
    case 1:
      result = (
        <ContactDetails
          saveHandler={onboardDeveloperPartnerRequest =>
            setOnboardDeveloperPartnerRequest((prevState: OnboardDeveloperPartnerExtendedRequest) => ({
              ...prevState,
              ...onboardDeveloperPartnerRequest,
            }))
          }
          initValue={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          watchProviderTypeValue={watch}
          setProviderTypeValue={setProviderTypeValue}
          currentStep={currentStep}
        />
      );
      break;
    case 2:
      result = (
        <TermsAndConditions
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          acceptTermsAndConditions={acceptTermsAndConditions}
          setAcceptTermsAndConditions={setAcceptTermsAndConditions}
          isDeveloperPartnerForm={true}
          currentStep={currentStep}
        />
      );
      break;
    case 3:
      result = (
        <Pricing
          savePartner={onboardDeveloperPartnerRequest =>
            setOnboardDeveloperPartnerRequest((prevState: OnboardDeveloperPartnerExtendedRequest) => ({
              ...prevState,
              ...onboardDeveloperPartnerRequest,
            }))
          }
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      );
      break;
    case 4:
      result = (
        <ProjectScopeGeneralInformation
          saveHandler={setOnboardDeveloperPartnerRequest}
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />);
      break;
    case 5:
      result = (
        <ContentSupplierSources
          saveHandler={setOnboardDeveloperPartnerRequest}
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />);
      break;
    case 6:
      result = (
        <SabreInteractions
          saveHandler={setOnboardDeveloperPartnerRequest}
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />);
      break;
    case 7:
      result = (
        <AdditionalInfo
          saveHandler={setOnboardDeveloperPartnerRequest}
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          watchDiagram={watchDiagram}
          setDiagram={setDiagram}
        />);
      break;
    case 8:
      result = (
        <ReviewAndSubmit
          onboardDeveloperPartnerRequest={onboardDeveloperPartnerRequest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          watchDiagram={watchDiagram}
        />
      );
      break;
    case 9:
      result = <EndMessage/>;
      break;
    case -1:
      result = <ErrorMessage/>;
      break;
  }

  return result;
};

export default DeveloperPartnerFormContent;
