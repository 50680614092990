/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentSupplierSources
 */
export interface ContentSupplierSources {
    /**
     * 
     * @type {string}
     * @memberof ContentSupplierSources
     */
    car?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSupplierSources
     */
    hotel?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSupplierSources
     */
    connectivity?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSupplierSources
     */
    air?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSupplierSources
     */
    rail?: string;
}

/**
 * Check if a given object implements the ContentSupplierSources interface.
 */
export function instanceOfContentSupplierSources(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContentSupplierSourcesFromJSON(json: any): ContentSupplierSources {
    return ContentSupplierSourcesFromJSONTyped(json, false);
}

export function ContentSupplierSourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentSupplierSources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'car': !exists(json, 'car') ? undefined : json['car'],
        'hotel': !exists(json, 'hotel') ? undefined : json['hotel'],
        'connectivity': !exists(json, 'connectivity') ? undefined : json['connectivity'],
        'air': !exists(json, 'air') ? undefined : json['air'],
        'rail': !exists(json, 'rail') ? undefined : json['rail'],
    };
}

export function ContentSupplierSourcesToJSON(value?: ContentSupplierSources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'car': value.car,
        'hotel': value.hotel,
        'connectivity': value.connectivity,
        'air': value.air,
        'rail': value.rail,
    };
}

