import React, {memo, useMemo} from 'react';
import {StepIndicatorType} from './StepIndicatorType';
import {ProposalItem} from '@scm/proposal';
import ProposalIndicator from '@scm/proposal/components/proposalIndicator/ProposalIndicator';
import styled from 'styled-components';
import {
  developerStepsValues,
  initialValues,
  onboardingProposalStepsValues,
  onboardingStepsValues
} from "./stepIndicatorValues";

export const FormStepIndicator = memo(
  ({
    currentStep,
    setCurrentStep,
    type,
    isPublicRedApp,
  }: {
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    type: StepIndicatorType;
    isPublicRedApp?: boolean;
  }) => {
    const steps = useMemo(() => {
      switch (type) {
        case StepIndicatorType.providerType:
          return (
            <ProposalIndicator>
              {initialValues.map((item, index) => (
                <ProposalItem
                  key={index}
                  value={item.value}
                  currentStep={currentStep}
                  labelId={item.labelId}
                  setCurrentStep={setCurrentStep}
                />
              ))}
            </ProposalIndicator>
          );
        case StepIndicatorType.joinUs:
          return (
            <DeveloperPartnerIndicator>
              <ProposalIndicator>
              {developerStepsValues.map((item, index) => (
                <ProposalItem
                  key={index}
                  value={item.value}
                  currentStep={currentStep}
                  labelId={item.labelId}
                  setCurrentStep={setCurrentStep}
                  isMultiline={item.isMultiline}
                />
              ))}
              </ProposalIndicator>
            </DeveloperPartnerIndicator>
          );
        case StepIndicatorType.onboardingJoinUs:
          return (
            <ProposalIndicator>
              {[...onboardingStepsValues, ...onboardingProposalStepsValues(isPublicRedApp)].map((item, index) => (
                <ProposalItem
                  key={index}
                  value={item.value}
                  currentStep={currentStep}
                  labelId={item.labelId}
                  setCurrentStep={setCurrentStep}
                />
              ))}
            </ProposalIndicator>
          );
        default:
          throw new Error('Invalid form indicator type.');
      }
    }, [type, isPublicRedApp, currentStep]);

    return <Container className="spark-mar-l-2 spark-pad-l-1">{steps}</Container>;
  }
);

const Container = styled.div`
  .spark-step-indicator__list {
    transform: translateX(-5rem) !important;
  }
`;

const DeveloperPartnerIndicator = styled.div`
    & .spark-step-indicator__item {
      margin: 1rem 1.5rem!important;
      &::after {
        left: calc(50% + 2.5rem);
        width: calc(100% - 1.5rem);
      }
    }
`;
